/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getContentReducer } from "../../store/content/content.selector";
import { getPlan } from "../../utils/helpers.utils";

import BackButton from "../../components/BackButton/BackButton.component";
import Empty from "../../components/Empty/Empty.component";
import Element from "../../components/Element/Element.component";
import { getProjectsReducer } from "../../store/projects/projects.selector";
import { useMemo } from "react";
import { setFromPage } from "../../store/lot/lot.reducer";

const Plan = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { projectID } = useParams();
  const { pathname, search } = useLocation();
  const projectsReducer = useSelector(getProjectsReducer);
  const {
    texts: { lotEmpty },
  } = useSelector(getContentReducer);
  const plan = useMemo(
    () => getPlan(projectsReducer, projectID),
    [projectsReducer, projectID]
  );

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return !plan ? (
    <Navigate to={`/project/${projectID}`} replace />
  ) : (
    <>
      <BackButton text={plan.planName} link={`/project/${projectID}`} />
      {plan.planLots && plan.planLots.length > 0 ? (
        <ul>
          {plan.planLots.map((plan) => (
            <li
              key={plan.attributes.slug}
              onClick={() => {
                dispatch(setFromPage(pathname + search));
              }}
            >
              <Element
                text={plan.attributes.name}
                link={plan.attributes.slug}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Empty text={lotEmpty} />
      )}
    </>
  );
};

export default Plan;
