/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { Link } from "react-router-dom";
import {
  getMinutesFromSeconds,
  removeProjectName,
} from "../../utils/helpers.utils";
import { ArrowRight } from "../../icons/ArrowRight.icon";
import { AutoPlay } from "../../icons/AutoPlay.icon";

import "./Element.styles.scss";

const Element = ({
  text,
  link,
  icon,
  selected = false,
  autoPlay = false,
  duration,
}) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <Link
      to={link}
      className={`element flex items-center relative mx-auto mb-6 p-6 ${
        selected && "selected"
      }`}
    >
      <hr className="absolute inset-0 h-full w-full bg-light-primary rounded-md" />
      {icon && (
        <img
          className="w-8 h-auto"
          src={`${process.env.REACT_APP_STRAPI + icon}`}
          alt={text}
        />
      )}
      <div
        className={
          "element__content flex justify-between items-center" +
          (icon && " element__content--has-icon pl-3")
        }
      >
        <p>
          {text && removeProjectName(text)}
          {duration && (
            <span className="text-primary px-3">
              {getMinutesFromSeconds(duration)}
            </span>
          )}
          {autoPlay && <AutoPlay className={selected ? "spin" : ""} />}
        </p>
        <ArrowRight className="icon" />
      </div>
    </Link>
  );
};

export default Element;
