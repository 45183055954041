/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLotReducer } from "../../store/lot/lot.selector";
import { getContentReducer } from "../../store/content/content.selector";
import BackButton from "../../components/BackButton/BackButton.component";
import Element from "../../components/Element/Element.component";
import Empty from "../../components/Empty/Empty.component";
import Loader from "../../components/Loader/Loader.component";

const Lot = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { pathname } = useLocation();
  const { projectID, scaleID } = useParams();
  const { loading, name, videos, pieces, piecesName, fromPage } =
    useSelector(getLotReducer);
  const {
    texts: { videosEmpty },
  } = useSelector(getContentReducer);

  /* url de retour */
  const defaultReturnPath = pathname.split("/").includes("plan")
    ? `/project/${projectID}/plan`
    : `/project/${projectID}/scale/${scaleID}/search/results`;

  const returnPath = useMemo(
    () => (fromPage ? fromPage : defaultReturnPath),
    [fromPage, defaultReturnPath]
  );

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton text={name} link={returnPath} />
      <div>
        {loading ? (
          <div className="relative h-56">
            <Loader />
          </div>
        ) : (
          <>
            {!videos.length && !pieces.length ? (
              <li>
                <Empty text={videosEmpty} />
              </li>
            ) : (
              <ul>
                {videos &&
                  videos.length > 0 &&
                  videos.map((video) => {
                    return (
                      <li key={video.id}>
                        <Element text={video.name} link={`video/${video.id}`} />
                      </li>
                    );
                  })}
                {pieces && pieces.length && (
                  <li>
                    <Element text={piecesName} link="pieces" />
                  </li>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Lot;
