/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth/auth.actions";
import { emptyError } from "../../store/auth/auth.reducer";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { getContentReducer } from "../../store/content/content.selector";
import { EyeIcon } from "../../icons/Eye.icon";
import Loader from "../Loader/Loader.component";
import Message from "../Message/Message.component";

const Login = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const {
    register,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { loading, error, expulsion } = useSelector(getAuthReducer);
  const {
    texts: {
      loginError,
      emailInput,
      passwordInput,
      loginButton,
      inputError,
      expulseMessage,
    },
  } = useSelector(getContentReducer);
  const [passwordVisible, setPasswordVisible] = useState(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(emptyError());
    // eslint-disable-next-line
  }, []);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const onSubmit = (fields) => {
    dispatch(login(fields));
    setError(error);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <form className="mt-16 relative" onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Message type="error" text={loginError} side="center" main="main" />
      )}
      {expulsion && (
        <Message type="error" text={expulseMessage} side="center" main="main" />
      )}
      {errors.email && <Message type="error" text={inputError} />}

      {loading && <Loader />}
      <label>
        <input
          type="text"
          placeholder={emailInput}
          {...register("email", { required: true })}
          onClick={() => {
            if (error) dispatch(emptyError());
            clearErrors();
          }}
          onKeyDown={() => {
            if (error) dispatch(emptyError());
            clearErrors();
          }}
          autoComplete="email"
        />
      </label>
      {errors.password && <Message type="error" text={inputError} />}
      <label className="relative">
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder={passwordInput}
          {...register("password", { required: true })}
          onClick={() => {
            if (error) dispatch(emptyError());
            clearErrors();
          }}
          onKeyDown={() => {
            if (error) dispatch(emptyError());
            clearErrors();
          }}
          autoComplete="current-password"
        />
        <div
          className="absolute top-0 right-0 p-3 w-12 h-full flex items-center cursor-pointer"
          onMouseDown={() => setPasswordVisible(true)}
          onMouseUp={() => setPasswordVisible(false)}
          onTouchStart={() => setPasswordVisible(true)}
          onTouchEnd={() => setPasswordVisible(false)}
        >
          <EyeIcon />
        </div>
      </label>
      <button type="submit">
        {loginButton ? loginButton : "se connecter"}
      </button>
    </form>
  );
};

export default Login;
