/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { Link } from "react-router-dom";
import Title from "../Title/Title.component";
import { ArrowLeft } from "../../icons/ArrowLeft.icon";
import "../../icons/ArrowLeft.icon";

import "./BackButton.styles.scss";

const BackButton = ({
  text,
  textButton = "Retour",
  link = "",
  action = false,
  back = true,
}) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div
      className={`back-button | sticky top-0 flex flex-wrap items-center bg-white z-50 ${
        back ? "justify-start" : "justify-center"
      }`}
    >
      {back && (
        <div className="w-auto md:w-4/12 absolute left-0 md:relative">
          {action ? (
            <button
              onClick={action}
              className="sticky top-0 flex justify-start items-center z-50 py-6"
            >
              <ArrowLeft className="icon" />
              <p className="pl-2">{textButton}</p>
            </button>
          ) : (
            <Link
              to={link}
              className="sticky top-0 flex justify-start items-center z-50 py-6"
            >
              <ArrowLeft className="icon" />
              <p className="pl-2">{textButton}</p>
            </Link>
          )}
        </div>
      )}

      {text && (
        <div className="w-full md:w-4/12 text-center relative z-10 px-[70px] md:px-0 py-6">
          <Title text={text} />
        </div>
      )}
    </div>
  );
};

export default BackButton;
