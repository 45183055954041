/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { getVideo } from "../../store/projects/projects.selector";
import { getContentReducer } from "../../store/content/content.selector";
import { getHistoryReducer } from "../../store/history/history.selector";
import { setLastPage } from "../../store/history/history.reducer";
import {
  resetContent,
  setCurrent,
} from "../../store/projects/projects.reducer";
import {
  emptyVideo,
  resetSockets,
  setSocketsNewContent,
  setSocketsNewImages,
  setSocketsReloadContent,
} from "../../utils/websockets.utils";
import VideoPlayer from "../../components/Video/Player/VideoPlayer.component";
import BackButton from "../../components/BackButton/BackButton.component";
import Empty from "../../components/Empty/Empty.component";
import Loader from "../../components/Loader/Loader.component";
import Image from "../../components/Image/Image.component";
import VideoOther from "../../components/Video/Other/VideoOther.component";

const Video = ({ socket, lotRoute = false }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectID, scaleID, videoID, lotID } = useParams();
  const { userID, socketID } = useSelector(getAuthReducer);
  const video = useSelector(getVideo(videoID));
  const {
    texts: { videoEmpty },
  } = useSelector(getContentReducer);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { lastPage } = useSelector(getHistoryReducer);

  const returnPath = pathname.split("/").includes("plan")
    ? `/project/${projectID}/plan/${lotID}`
    : `/project/${projectID}/scale/${scaleID}/search/results/${lotID}`;

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /* leave page */
  useEffect(() => {
    /* if is first page */
    if (!lastPage) navigate("/");
    dispatch(setLastPage("video"));

    return () => {
      dispatch(resetContent());
      emptyVideo(socketID);
      resetSockets(socketID);
    };
    // eslint-disable-next-line
  }, []);

  /* set timeout if not loaded */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false);
        setError(true);
      }
    }, 6000);
    return () => clearTimeout(timer);
  }, [loading]);

  /* set video file */
  useEffect(() => {
    loadContent();
    // eslint-disable-next-line
  }, [video, userID]);

  /* leaving page */
  useEffect(() => {
    return () => {
      dispatch(resetContent());
      resetSockets(socketID);
    };
    // eslint-disable-next-line
  }, []);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  /* errors & loading*/
  const isError = (error) => {
    if (error) setError(true);
  };

  const isLoaded = () => {
    setLoading(false);
  };

  const reloadContent = () => {
    if (video.file) {
      dispatch(setCurrent(video.file));
      setSocketsReloadContent(
        video.file,
        video.type === "Player" ? "video" : "images",
        socketID
      );
    }
  };

  const loadContent = () => {
    if (video.file) {
      if (video.type === "Image") {
        dispatch(setCurrent(video.file));
        setSocketsNewImages([video.file], false, socketID);
      } else {
        dispatch(setCurrent(video.file));
        setSocketsNewContent(
          video.file,
          video.type === "Player" ? "video" : video.type,
          socketID
        );
      }
    }
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton
        text={video.name}
        link={lotRoute ? returnPath : `/project/${projectID}/scale/${scaleID}`}
      />
      <div className="relative">
        {video && !error ? (
          <div
            className={`flex flex-wrap justify-center ${
              loading ? "opacity-0" : "opacity-100"
            }`}
          >
            {/* Video */}
            {video.type === "Player" && (
              <VideoPlayer
                video={video}
                socketID={socketID}
                socket={socket}
                isError={isError}
                isLoaded={isLoaded}
                reloadContent={reloadContent}
              />
            )}

            {/* Slider */}
            {(video.type === "Slider" || video.type === "Rotary") && (
              <VideoOther
                video={video}
                socketID={socketID}
                socket={socket}
                isError={isError}
                isLoaded={isLoaded}
              />
            )}

            {/* Image */}
            {video.type === "Image" && (
              <Image socket={socket} isError={isError} isLoaded={isLoaded} />
            )}
          </div>
        ) : (
          <Empty text={videoEmpty} />
        )}
        {loading && (
          <div className="absolute inset-0 bg-white">
            <div className="relative h-56">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Video;
