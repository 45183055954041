/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { Link, Navigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader.component";

import "./Available.styles.scss";
import { getContentReducer } from "../../store/content/content.selector";

const Available = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { isAdmin } = useSelector(getAuthReducer);
  const {
    texts: { qrcodePageButton },
  } = useSelector(getContentReducer);

  const [users, setUsers] = useState(null);
  const [details, setDetails] = useState(null);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    // checkAvailable();
    if (socket) {
      socket.emit("available");
      socket.on("total", totalAvailable);
      socket.on("totalDetails", totalDetailsAvailable);
    }
    return () => {
      if (socket) {
        socket.off("total", totalAvailable);
        socket.off("totalDetails", totalDetailsAvailable);
      }
    };
  }, [socket]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const totalAvailable = (total) => {
    setUsers(total);
  };
  const totalDetailsAvailable = (totalDetails) => {
    const detailsSentence = totalDetails.reduce((sentence, totalDetail) => {
      const roomDetail = `<h1>Client ${totalDetail.name}</h1> <p>${totalDetail.members} utilisateur(s)</p>`;
      return `${sentence} ${roomDetail}`;
    }, "");

    setDetails(detailsSentence);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return !isAdmin ? (
    <Navigate to="/" replace />
  ) : (
    <div
      className={`available height-full flex flex-wrap justify-center items-center ${
        users != null && users <= 1 && "available--true"
      }`}
    >
      <div className="w-full text-center button-parent">
        <Link
          className="button text-center mb-10 max-w-md mx-auto"
          to="/qrcode"
        >
          {qrcodePageButton}
        </Link>

        <div className="available__list">
          {users != null ? (
            users > 1 ? (
              <span dangerouslySetInnerHTML={{ __html: details }} />
            ) : (
              "disponible"
            )
          ) : (
            <div className="relative h-56">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Available;
