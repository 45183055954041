/*//////////////////////*/
/* Variables
/*//////////////////////*/
const root = document.documentElement;
const primary = '#242424';


/**
 * Couleurs d'un utilisateur
 * @param {array} colors
 * @return void
 */
export const setColors = (colors) => {
  const { colorTexts, colorPrimary } = colors;
  root?.style.setProperty('--color-texts', colorTexts ? colorTexts : primary);
  root?.style.setProperty('--color-primary', colorPrimary ? colorPrimary : primary);
  sessionStorage.setItem('hoopp-colors', JSON.stringify(colors));
};


/**
 * Reset des couleurs
 * @return void
 */
export const resetColors = () => {
  root?.style.setProperty('--color-texts', primary);
  root?.style.setProperty('--color-primary', primary);
  sessionStorage.removeItem('hoopp-colors');
};
