const minDifference = 1;

/* Pourcentage à partir d'un angle */
export const getPercentFromAngle = (angle) => {
  let rotation = angle;
  while (rotation >= 360) rotation -= 360;
  while (rotation < 0) rotation += 360;
  return Math.round((rotation / 360) * 100);
};

/* Angle à partir d'un pourcentage */
export const getAngleFromPercent = (percent) => {
  return Math.round((percent * 360) / 100);
};

/* Angle à partir de GSAP */
export const calculateAngle = (angle) => {
  return Math.abs(
    angle < 0 ? Math.round(360 + angle) % 360 : Math.round(angle) % 360
  );
};

/* Retrouver le chemin d'une image */
export const getImageURL = (url) => {
  return process.env.REACT_APP_STRAPI + url;
};

/* Différence entre deux valeurs */
export const getDifference = (a, b) => {
  return Math.abs(a - b) > minDifference ? true : false;
};

/* Retrouver temps à partir d'un pourcentage */
export const getTimeFromPercent = (percent, time) => {
  return (percent / 100) * time;
};

/* Retirer le [nom du projet] d'un texte */
export const removeProjectName = (content) => {
  return content.replace(/\[.+?\]/, "");
};

/* Valeur à partir d'un pourcentage et d'un range */
export const getValueFromRangeAndPercent = (percent, start, range) => {
  return Math.round((percent / 100) * range + parseInt(start));
};

/* Horaire à partir d'un pourcentage et d'un range */
export const getHoursFromRangeAndPercent = (percent, start, range) => {
  const currentSeconds = Math.round(
    (percent / 100) * range + parseInt(getSecondsFromHours(start))
  );
  return getHoursFromSeconds(currentSeconds);
};

/* Convert hours en seconds */
export const getSecondsFromHours = (time) => {
  const timeDetails = time.split("h");
  return timeDetails[1]
    ? Math.round(
        parseInt(timeDetails[0]) * 3600 + parseInt(timeDetails[1]) * 3600
      )
    : Math.round(parseInt(timeDetails[0]) * 3600);
};

/* Convert secondes en heures */
export const getHoursFromSeconds = (time) => {
  const hours = Math.floor(time / 3600);
  const minuts = Math.floor((time % 3600) / 60);
  return `${hours}h${("0" + minuts.toString()).slice(-2)}`;
};

/* Convert secondes en minutes */
export const getMinutesFromSeconds = (time) => {
  const minuts = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minuts > 0 ? minuts + "m" : ""}${seconds > 0 ? seconds + "s" : ""}`;
};

/* Convert url to filename  */
export const getFileName = (url) => {
  const [...parts] = url.split("/");
  const fileName = parts.pop();
  const [...segments] = fileName.split(".");
  return segments[0];
};

/* Convert url to extension  */
export const getExtension = (url) => {
  const [...parts] = url.split("/");
  const fileName = parts.pop();
  const [...segments] = fileName.split(".");
  return segments.pop();
};

/* Update joystick angle  */
export const updateJoystickAngle = (position, currentAngle) => {
  return position > 0 ? (currentAngle += 1) : (currentAngle -= 1);
};

/* Filter videos  */
export const getAutoplayVideos = (projectsReducer, projectID, scaleID) => {
  return projectsReducer.videos.filter(
    (video) =>
      video.projectID === +projectID &&
      video.scaleID === +scaleID &&
      video.auto === true &&
      video.type === "Player"
  );
};

export const getProjectColors = (projectsReducer, projectID) => (state) => {
  if (!projectID || !projectsReducer) return;
  const currentProject =
    projectsReducer && projectsReducer?.projects
      ? projectsReducer.projects.find((project) => project.id === +projectID)
      : null;
  let colors = {};

  if (currentProject) {
    if (currentProject?.colorTexts)
      colors.colorTexts = currentProject.colorTexts;
    if (currentProject?.colorPrimary)
      colors.colorPrimary = currentProject.colorPrimary;
  }

  return colors;
};

export const getScaleVideos = (projectsReducer, projectID, scaleID) => {
  return projectsReducer.videos.filter(
    (video) => video.projectID === +projectID && video.scaleID === +scaleID
  );
};

export const getPlan = (projectsReducer, projectID) => {
  const currentProject = projectsReducer.projects.find(
    (project) => project.id === +projectID
  );
  return currentProject && currentProject?.plan && currentProject.plan === true
    ? {
        plan: currentProject.plan,
        planName: currentProject.planName,
        planIcon: currentProject.planIcon,
        planLots: currentProject.planLots,
      }
    : null;
};

export const getScale = (projectsReducer, projectID, scaleID) => {
  const currentProject = projectsReducer.projects.find(
    (project) => project.id === +projectID
  );

  const currentScale = currentProject
    ? currentProject.scales.find((scale) => scale.id === +scaleID)
    : null;
  return currentScale ? currentScale : null;
};

/* Retrouve un paramètre et le map  */
export const getSearchOption = (params, listValues) => {
  return params
    ? params.split(",").map((paramSingle) => {
        const listSingle = listValues.find(
          (listValue) => listValue.value === paramSingle
        );
        return {
          label: listSingle?.label ? listSingle.label : paramSingle,
          value: listSingle?.value ? listSingle.value : paramSingle,
        };
      })
    : "";
};

/* Liste des routes  */
export const getProjectsRoutes = (projects, videos) => {
  const projectsRoutes = projects.map((project) => ({
    id: project.id,
    scales: project?.scales
      ? project.scales.map((scale) => {
          const scaleVideos = videos.filter(
            (video) => video.scaleID === scale.id
          );
          return {
            id: scale.id,
            videos: scaleVideos
              ? scaleVideos.map((video) => ({
                  id: video.scaleID,
                }))
              : [],
          };
        })
      : [],
  }));
  return projectsRoutes;
};
