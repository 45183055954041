/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import "./Message.styles.scss";

/*//////////////////////*/
/* Types
/*/ /////////////////////*/
const CONTENT_TYPES = {
  error: "error",
  success: "success",
};

const Message = ({ text, type, side = "left", main, className = "" }) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <p
      className={`message ${CONTENT_TYPES[type]} ${side} ${main} ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Message;
