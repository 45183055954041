export const OrientationIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M92.4 31.6c-.5-.6-1.4-.8-2.1-.5L47.9 46.7c-.6.2-1 .6-1.2 1.2L31.1 90.3c-.3.7-.1 1.6.5 2.1.4.4.9.6 1.4.6.2 0 .5 0 .7-.1l41.4-16.6c.5-.2.9-.6 1.1-1.1l16.6-41.4c.3-.8.2-1.6-.4-2.2zM72.9 72.9 36.4 87.5l13.7-37.3 37.3-13.7-14.5 36.4z"
    />
    <path
      className="fill-primary"
      d="M62 72.3c5.7 0 10.3-4.6 10.3-10.3S67.7 51.7 62 51.7 51.7 56.3 51.7 62 56.3 72.3 62 72.3zm0-16.6c3.5 0 6.3 2.8 6.3 6.3s-2.8 6.3-6.3 6.3-6.3-2.8-6.3-6.3 2.8-6.3 6.3-6.3z"
    />
  </svg>
);
