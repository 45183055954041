export const HooppIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 447.9 228.5"
  >
    <path d="M66.8 15.6H31.3V0H0v87.3h31.3V45.1h35.5v42.2h31.3V0H66.8zM172.6 49.2c30.9 0 55-20.6 59.4-49.1h-32.9C195.5 11.4 185.2 19 172.6 19S149.8 11.4 146.1.1h-32.9c4.4 28.5 28.5 49.1 59.4 49.1zM172.6 55.2c-34.3 0-60.2 25.3-60.2 58.6s25.9 58.6 60.2 58.6c34.3 0 60.2-25.3 60.2-58.6.1-33.3-25.9-58.6-60.2-58.6zm0 87c-15.9 0-28.1-12.1-28.1-28.4 0-16.2 12.1-28.4 28.1-28.4 15.9 0 28.1 12.1 28.1 28.4 0 16.3-12.2 28.4-28.1 28.4zM172.9 178.4c-31.1 0-55.3 20.9-59.5 49.6h32.8c3.6-11.5 13.9-19.4 26.7-19.4 12.8 0 23.2 7.9 26.7 19.4h32.8c-4.2-28.8-28.4-49.6-59.5-49.6zM299.9 133.2h-52.5v95.3h31.3v-14.9h21.2c23.8 0 41.8-17.2 41.8-40.2 0-23-18-40.2-41.8-40.2zm-.7 50.9h-20.5v-21.3h20.5c5.9 0 10.3 4.6 10.3 10.7.1 6-4.4 10.6-10.3 10.6zM406.1 116.1h-52.5v112.4h31.3v-32h21.2c23.8 0 41.8-17.2 41.8-40.2 0-23-18-40.2-41.8-40.2zm-.7 50.8h-20.5v-21.3h20.5c5.9 0 10.3 4.6 10.3 10.7s-4.4 10.6-10.3 10.6z" />
  </svg>
);
