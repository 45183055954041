/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSlice } from "@reduxjs/toolkit";
import { setProjects, setVideos } from "./projects.actions";
import { getFormatProjects, getFormatVideos } from "../../utils/format.utils";

/*//////////////////////*/
/* Initial values
/*/ /////////////////////*/
export const INITIAL_STATE_PROJECTS = {
  loading: false,
  projects: [],
  projectsIDs: [],
  videos: [],
  type: null,
  current: null,
  currentDuration: 0,
  currentPercent: 0,
  currentScale: null,
  currentProject: null,
  error: false,
  success: false,
};

/*//////////////////////*/
/* Slice
/*/ /////////////////////*/
export const projectsSlice = createSlice({
  name: "projects",
  initialState: INITIAL_STATE_PROJECTS,
  reducers: {
    setType(state, { payload }) {
      state.type = payload;
    },
    setCurrent(state, { payload }) {
      state.current = payload;
    },
    setCurrentDuration(state, { payload }) {
      state.currentDuration = payload;
    },
    setCurrentPercent(state, { payload }) {
      state.currentPercent = payload;
    },
    setCurrentScale(state, { payload }) {
      state.currentScale = payload;
    },
    setCurrentProject(state, { payload }) {
      state.currentProject = payload;
    },
    resetContent(state) {
      state.type = null;
      state.current = null;
      state.currentDuration = 0;
      state.currentPercent = 0;
    },
    emptyProjects(state) {
      state.projects = [];
      state.videos = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setProjects.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setProjects.fulfilled, (state, { payload }) => {
        if (payload?.data && payload.data != null) {
          const projects = getFormatProjects(payload.data);
          const projectsIDs = projects.map((project) => project.id);
          state.loading = false;
          state.success = true;
          state.projectsIDs = projectsIDs;
          state.projects = projects;
          state.error = false;
        } else {
          state.loading = false;
          state.success = false;
          state.error = true;
        }
      })
      .addCase(setProjects.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setVideos.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setVideos.fulfilled, (state, { payload }) => {
        if (payload?.data && payload.data != null) {
          const videos = getFormatVideos(payload.data);
          state.loading = false;
          state.success = true;
          state.videos = videos;
          state.error = false;
        } else {
          state.loading = false;
          state.success = false;
          state.error = true;
        }
      })
      .addCase(setVideos.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  emptyProjects,
  setCurrent,
  setCurrentDuration,
  setCurrentPercent,
  setCurrentScale,
  setCurrentProject,
  resetContent,
} = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
