/*//////////////////////*/
/* Imports
/*//////////////////////*/
// import { createSelector } from '@reduxjs/toolkit';


/*//////////////////////*/
/* Exports
/*//////////////////////*/
export const getAuthReducer = (state) => state.auth;

