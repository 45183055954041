import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../components/Alert/Alert.component";
import Footer from "../components/Footer/Footer.component";
import Navigation from "../components/Navigation/Navigation.component";
import Orientation from "../components/Orientation/Orientation.component";

/* Protection de route */
export const ProtectedRoute = ({
  userToken,
  redirectPath = "/signin",
  socket,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const [isHome, setIsHome] = useState(false);
  const location = useLocation();

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    setIsHome(location.pathname === "/" ? true : false);
  }, [location]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  if (!userToken) {
    return <Navigate to={redirectPath} replace />;
  } else {
    return (
      <>
        <Orientation />
        <Alert socket={socket} />
        <Navigation isHome={isHome} />
        <main className="flex flex-wrap content-between">
          <div className="main__container">
            <Outlet />
          </div>
          <Footer contact={location.pathname !== "/contact" ? true : false} />
        </main>
      </>
    );
  }
};
