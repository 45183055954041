/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo, login } from "./auth.actions";

/*//////////////////////*/
/* Initial values
/*/ /////////////////////*/
export const INITIAL_STATE_AUTH = {
  loading: false,
  user: {},
  avatar: null,
  logoUrl: null,
  userID: null,
  userToken: null,
  error: false,
  success: false,
  expulsion: false,
  isAdmin: false,
  socketID: null,
  singleProject: false,
  cursor: true,
};

/*//////////////////////*/
/* Slice
/*/ /////////////////////*/
export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE_AUTH,
  reducers: {
    logout(state) {
      state.user = {};
      state.userToken = null;
      state.avatar = null;
      state.logoUrl = null;
      state.id = null;
      state.expulsion = false;
      state.isAdmin = false;
      state.userID = null;
      state.socketID = null;
      state.singleProject = false;
    },
    emptyError(state) {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.expulsion = false;
    },
    expulsion(state) {
      state.user = {};
      state.userToken = null;
      state.avatar = null;
      state.logoUrl = null;
      state.id = null;
      state.expulsion = true;
      state.isAdmin = false;
      state.userID = null;
      state.socketID = null;
      state.singleProject = false;
    },
    setCursor(state, { payload }) {
      state.cursor = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.expulsion = false;

        if (payload?.error || !payload?.user) {
          state.success = false;
          state.error = true;
        } else {
          const admin =
            payload?.user &&
            payload.user.admin === true &&
            payload?.user?.userControl;
          if (admin) state.isAdmin = true;
          state.socketID = payload.user.id;
          state.success = true;
          state.error = false;
          state.user = payload.user;
          state.userID = admin ? payload.user.userControl : payload.user.id;
          state.userToken = payload.jwt;
          state.singleProject = payload.user.singleProject;
        }
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.expulsion = true;
        state.avatar =
          payload?.logo && payload.logo != null ? payload.logo.url : null;
        state.logoUrl =
          payload?.logoApp && payload.logoApp != null
            ? payload.logoApp.url
            : null;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { logout, emptyError, expulsion, setCursor } = authSlice.actions;
export const authReducer = authSlice.reducer;
