/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState } from "react";
import "./Alert.styles.scss";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { useSelector } from "react-redux";

const Alert = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { socketID } = useSelector(getAuthReducer);
  const [visible, setVisible] = useState(false);
  const [clients, setClients] = useState(0);
  const [admins, setAdmins] = useState(0);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    if (socket) {
      socket.on("users", showAlert);
      if (socketID) {
        document.addEventListener("keydown", sendAlert);
      }
    }
    return () => {
      if (socket) {
        socket.off("users", showAlert);
        document.removeEventListener("keydown", sendAlert);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, socketID]);

  const showAlert = ({ clients, admins }) => {
    setClients(clients);
    setAdmins(admins);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const sendAlert = (e) => {
    if (e.ctrlKey && e.code === "KeyU") {
      socket.emit("users", socketID);
    }
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      {visible && (
        <div className="alert | fixed top-0 left-0 flex justify-center items-center">
          <div className="alert__inner | p-10 bg-white text-center">
            <b>{clients}</b> client(s), <b>{admins}</b> admin(s)
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
