/*//////////////////////*/
/* Imports
/*//////////////////////*/
import { LoaderIcon } from "../../icons/Loader.icon";
import "./Loader.styles.scss";

const Loader = () => {

  /*//////////////////////*/
  /* Return
  /*//////////////////////*/
  return (
    <div className="loader flex justify-center items-center">
      <LoaderIcon />
    </div>
  );
};

export default Loader;
