export const LoaderIcon = () => (
  <svg
    className="rotate"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117.2 117.2"
    xmlSpace="preserve"
  >
    <linearGradient
      id="loader__gradient"
      gradientUnits="userSpaceOnUse"
      x1="58.58"
      y1="102.785"
      x2="58.58"
      y2="-14.374"
    >
      <stop offset="0" style={{ stopColor: "#ed3b91" }} />
      <stop offset="1" style={{ stopColor: "#854e9e" }} />
    </linearGradient>
    <path
      d="M58.6 0C26.2 0 0 26.2 0 58.6c0 32.4 26.2 58.6 58.6 58.6 32.4 0 58.6-26.2 58.6-58.6C117.2 26.2 90.9 0 58.6 0zm0 86.6c-15.5 0-28.1-12.6-28.1-28.1 0-15.5 12.6-28.1 28.1-28.1 15.5 0 28.1 12.6 28.1 28.1-.1 15.6-12.6 28.1-28.1 28.1z"
      style={{ fill: "url(#loader__gradient)" }}
    />
  </svg>
);
