/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser, getStrapiUser } from "../../utils/strapi.utils";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/

/* Connexion */
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      return getStrapiUser(email, password);
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

/* Informations utilisateur */
export const getUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async ({ userToken, userID }, { rejectWithValue }) => {
    try {
      return getCurrentUser(userToken, userID);
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);
