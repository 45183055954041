/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSelector } from "@reduxjs/toolkit";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/

export const getProjectsReducer = (state) => state.projects;

export const getProjects = createSelector(
  [getProjectsReducer],
  (projects) => projects.projects
);

export const getVideos = createSelector(
  [getProjectsReducer],
  (projects) => projects.videos
);

export const getProjectsIDs = createSelector(
  [getProjectsReducer],
  (projects) => {
    return projects.projects.map((project) => project.id);
  }
);

export const getSingleProjectURL = createSelector(
  [getProjectsReducer],
  (projects) => {
    return projects.projects.length &&
      projects.projects[0]?.id &&
      projects.projects[0].scales.length &&
      projects.projects[0].scales[0]?.id
      ? `project/${projects.projects[0].id}/scale/${projects.projects[0].scales[0].id}`
      : "/";
  }
);

export const getScales = (projectID) => (state) => {
  const currentProject = state.projects.projects.find(
    (project) => project.id === +projectID
  );

  return currentProject &&
    currentProject?.scales &&
    currentProject.scales.length > 0
    ? currentProject.scales
    : null;
};

export const getVideo = (videoID) => (state) => {
  return state.projects.videos.find((video) => video.id === +videoID);
};

export const getProjectLogoURL = (projectID) => (state) => {
  if (!projectID) return;
  const currentProject = state.projects.projects.find(
    (project) => project.id === +projectID
  );
  return currentProject && currentProject?.logo ? currentProject.logo : null;
};

export const getCurrentProject = (projectID) => (state) => {
  if (!projectID) return;
  const currentProject = state.projects.projects.find(
    (project) => project.id === +projectID
  );
  return currentProject;
};
