/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useOrientation } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

import "./Orientation.styles.scss";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { RotateIcon } from "../../icons/Rotate.icon";

const Orientation = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const orientation = useOrientation();
  const [isMobile, setIsMobile] = useState(false);
  const {
    texts: { updateOrientation },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if ("ontouchstart" in document.documentElement) setIsMobile(true);
  }, []);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    isMobile &&
    orientation.type === "landscape-primary" && (
      <div className="orientation">
        <div className="orientation__content | rounded-md">
          <RotateIcon className="w-28 mx-auto mb-6" />
          {updateOrientation}
        </div>
      </div>
    )
  );
};

export default Orientation;
