/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStrapiProjects, getStrapiVideos } from "../../utils/strapi.utils";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/

export const setProjects = createAsyncThunk(
  "projects/setProjects",
  async ({ userID, userToken }, { rejectWithValue }) => {
    try {
      return getStrapiProjects(userID, userToken);
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

export const setVideos = createAsyncThunk(
  "projects/setVideos",
  async ({ userToken }, { getState, rejectWithValue }) => {
    const { projectsIDs } = getState().projects;
    const { isAdmin } = getState().auth;
    if (projectsIDs.length > 0) {
      try {
        return getStrapiVideos(projectsIDs, userToken, isAdmin);
      } catch (err) {
        return rejectWithValue("Opps there seems to be an error");
      }
    } else {
      return { data: [] };
    }
  }
);
