/**
 * Liste de paramètres
 */
export const parameters = [
  "minSurface",
  "maxSurface",
  "pieces",
  "options",
  // "batiments",
  "etages",
  "orientations",
  "minPrix",
  "maxPrix",
];

/**
 * Retrouver informations d'un programme VB
 * @param {string} programmeName
 * @returns
 */
export const getProgrammeDatas = async (programmeName) => {
  const settings = {
    mode: "cors",
  };
  let response;
  try {
    response = await fetch(
      `${process.env.REACT_APP_VB}/programme/${programmeName}`,
      settings
    );
  } catch (error) {
    console.log(error);
  }

  if (response?.ok) {
    const data = await response.json();
    return data;
  } else {
    return null;
  }
};

/**
 * Retrouver les lots VB d'une recherche
 * @param {string} programmeName
 * @param {Array} options
 * @returns
 */
export const getLots = async (
  programmeName,
  searchBatiment,
  searchDetails,
  searchPrintOnly,
  printLots
) => {
  const url = `${process.env.REACT_APP_VB}/${programmeName}/lots`;
  const settings = {
    mode: "cors",
  };

  let response;
  try {
    response = await fetch(url, settings);
  } catch (error) {
    console.log(error);
  }

  if (response?.ok) {
    const data = await response.json();
    let lots = searchBatiment
      ? data.lots.filter((lot) => lot.batiment === searchBatiment)
      : data.lots;

    if (searchDetails) {
      lots = lots.map((lot) => ({
        ...lot,
        printed: printLots.includes(lot.name),
      }));
    }

    if (searchPrintOnly) {
      lots = lots.filter((lot) => printLots.includes(lot.name));
    }

    return lots;
  } else {
    return null;
  }
};

export const getFilteredLots = (lots, infos) => {
  /* Filtre des résultats */
  if (infos.minSurface)
    lots = lots.filter((lot) => lot.surface >= infos.minSurface);
  if (infos.maxSurface)
    lots = lots.filter((lot) => lot.surface <= infos.maxSurface);
  if (infos.minPrix)
    lots = lots.filter((lot) => lot.prix > 0 && lot.prix >= infos.minPrix);
  if (infos.maxPrix)
    lots = lots.filter((lot) => lot.prix > 0 && lot.prix <= infos.maxPrix);
  if (infos.pieces)
    lots = lots.filter((lot) =>
      infos.pieces.split(",").includes(lot.numPieces.toString())
    );
  if (infos.batiments)
    lots = lots.filter((lot) =>
      infos.batiments.split(",").includes(lot.batiment)
    );
  if (infos.etages)
    lots = lots.filter((lot) => infos.etages.split(",").includes(lot.etage));
  if (infos.options)
    lots = lots.filter((lot) =>
      infos.options.split(",").some((option) => lot.options.includes(option))
    );
  if (infos.orientations)
    lots = lots.filter((lot) =>
      infos.orientations
        .split(",")
        .some(
          (orientation) =>
            lot.orientation.includes(orientation) &&
            lot.orientation !== "Non-spécifiée"
        )
    );

  return lots;
};
