/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSlice } from "@reduxjs/toolkit";
import { setLot } from "./lot.actions";

/*//////////////////////*/
/* Initial values
/*/ /////////////////////*/
export const INITIAL_STATE_LOT = {
  loading: false,
  error: false,
  success: false,
  angleX: 0,
  angleY: 0,
  name: "",
  piecesName: "",
  fromPage: "",
  rotaryNorth: "",
  rotaryEast: "",
  rotarySouth: "",
  rotaryWest: "",
  videos: [],
  pieces: [],
};

/*//////////////////////*/
/* Slice
/*/ /////////////////////*/
export const lotSlice = createSlice({
  name: "lot",
  initialState: INITIAL_STATE_LOT,
  reducers: {
    resetLot(state) {
      state.name = "";
      state.piecesName = "";
      state.angleX = 0;
      state.angleY = 0;
      state.fromPage = "";
      state.rotaryNorth = "";
      state.rotaryEast = "";
      state.rotarySouth = "";
      state.rotaryWest = "";
      state.videos = [];
      state.pieces = [];
    },
    resetPieces(state) {
      state.angleX = 0;
      state.angleY = 0;
    },
    setFromPage(state, { payload }) {
      state.fromPage = payload;
    },
    setAngleX(state, { payload }) {
      state.angleX = payload;
    },
    setAngleY(state, { payload }) {
      state.angleY = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLot.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setLot.fulfilled, (state, { payload }) => {
        if (payload?.data && payload.data != null && payload.data.length > 0) {
          const currentLot = payload.data[0].attributes;
          state.name = currentLot?.name ? currentLot.name : "";
          state.piecesName = currentLot?.piecesName
            ? currentLot.piecesName
            : "Intérieurs";
          state.videos = currentLot?.videos
            ? currentLot.videos.data.map((video) => ({
                id: video.id,
                name: video.attributes.name,
                videoDuration: video.attributes.videoDuration,
              }))
            : [];
          state.pieces = currentLot?.pieces ? currentLot.pieces : [];
          state.rotaryNorth = currentLot?.rotaryNorth
            ? currentLot.rotaryNorth
            : "";
          state.rotaryEast = currentLot?.rotaryEast
            ? currentLot.rotaryEast
            : "";
          state.rotarySouth = currentLot?.rotarySouth
            ? currentLot.rotarySouth
            : "";
          state.rotaryWest = currentLot?.rotaryWest
            ? currentLot.rotaryWest
            : "";

          state.loading = false;
          state.success = true;
          state.error = false;
        } else {
          state.loading = false;
          state.success = false;
          state.error = true;
        }
      })
      .addCase(setLot.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { resetLot, setFromPage, setAngleX, setAngleY, resetPieces } =
  lotSlice.actions;

export const lotReducer = lotSlice.reducer;
