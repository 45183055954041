/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSlice } from "@reduxjs/toolkit";
import { setProgrammeDatas, setLots } from "./programme.actions";

/*//////////////////////*/
/* Initial values
/*/ /////////////////////*/
export const INITIAL_STATE_PROGRAMME = {
  lots: null,
  programmeDatas: null,
  errorDatas: false,
  errorLots: false,
  success: false,
  loading: false,
};

/*//////////////////////*/
/* Slice
/*/ /////////////////////*/
export const programmeSlice = createSlice({
  name: "programme",
  initialState: INITIAL_STATE_PROGRAMME,
  reducers: {
    emptyProgramme(state) {
      state.programmeDatas = null;
    },
    emptyLots(state) {
      state.lots = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setProgrammeDatas.pending, (state) => {
        state.loading = true;
        state.errorDatas = false;
      })
      .addCase(setProgrammeDatas.fulfilled, (state, { payload }) => {
        if (payload) {
          state.programmeDatas = payload;
          state.loading = false;
          state.success = true;
          state.errorDatas = false;
        } else {
          state.programmeDatas = null;
          state.loading = false;
          state.success = false;
          state.errorDatas = true;
        }
      })
      .addCase(setProgrammeDatas.rejected, (state) => {
        state.loading = false;
        state.errorDatas = true;
      })
      .addCase(setLots.pending, (state) => {
        state.loading = true;
        state.errorLots = false;
      })
      .addCase(setLots.fulfilled, (state, { payload }) => {
        if (payload) {
          state.lots = payload;
          state.loading = false;
          state.success = true;
          state.errorLots = false;
        } else {
          state.lots = null;
          state.loading = false;
          state.success = false;
          state.errorLots = true;
        }
      })
      .addCase(setLots.rejected, (state) => {
        state.loading = false;
        state.errorLots = true;
      });
  },
});

export const { emptyProgramme, emptyLots } = programmeSlice.actions;

export const programmeReducer = programmeSlice.reducer;
