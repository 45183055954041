export const BatimentIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M82.5 27.1H64v-4.8c0-1.1-.9-2-2-2s-2 .9-2 2v4.8H41.5c-1.1 0-2 .9-2 2V96c0 1.1.9 2 2 2s2-.9 2-2V31.1h36.9V96c0 1.1.9 2 2 2s2-.9 2-2V29.1c.1-1.1-.8-2-1.9-2z"
    />
    <path
      className="fill-primary"
      d="M57.9 38.2h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 38.2h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM57.9 47.8h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 47.8h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM57.9 57.4h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 57.4h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM57.9 67h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 67h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM57.9 76.6h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 76.6h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM57.9 86.2h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2zM74.1 86.2h-7.5c-1.1 0-2 .9-2 2s.9 2 2 2h7.5c1.1 0 2-.9 2-2s-.9-2-2-2z"
    />
  </svg>
);
