/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLotContent } from "../../utils/strapi.utils";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/

export const setLot = createAsyncThunk(
  "lot/setLot",
  async ({ lotID, projectID, userToken }, { rejectWithValue }) => {
    try {
      return getLotContent(lotID, projectID, userToken);
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);
