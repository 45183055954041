/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { getProgrammeReducer } from "../../store/programme/programme.selector";
import { getFilteredLots, parameters } from "../../utils/virtualbuilding.utils";
import { CheckedIcon } from "../../icons/Checked.icon";
import { getAuthReducer } from "../../store/auth/auth.selector";
import {
  setSocketsSearchImagesVisible,
  setSocketsSearchSelected,
} from "../../utils/websockets.utils";

import BackButton from "../../components/BackButton/BackButton.component";
import SearchButton from "../../components/SearchButton/SearchButton.component";
import Empty from "../../components/Empty/Empty.component";
import Lot from "../../components/Lot/Lot.component";

import "../../components/Lot/Lot.styles.scss";

const Results = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { lots, programmeDatas, errorLots, errorDatas } =
    useSelector(getProgrammeReducer);
  const { projectID, scaleID } = useParams();

  const {
    texts: {
      searchNew,
      searchNull,
      searchQuantity,
      searchAll,
      videoEmpty,
      apiError,
    },
  } = useSelector(getContentReducer);
  const { search } = useLocation();
  const { socketID } = useSelector(getAuthReducer);
  const [searchParams] = useSearchParams();
  const [filteredLots, setFilteredLots] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedLots, _setSelectedLots] = useState([]);
  const [isError, setIsError] = useState(false);
  const [ecranLot, setEcranLot] = useState(null);
  const selectedLotsRef = useRef([]);
  const socketsReady = useRef(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("videoRemote", videoRemote);
      socketsReady.current = true;
    }
    return () => {
      if (socket) socket.off("videoRemote", videoRemote);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (lots) {
      const infos = {};
      let parametersWithBatiments = [...parameters, "batiments"];
      parametersWithBatiments.forEach((parameter) => {
        if (searchParams.get(parameter))
          infos[parameter] = searchParams.get(parameter);
      });
      setFilteredLots(getFilteredLots(lots, infos));
    }
  }, [lots, searchParams]);

  /* Update lots sélectionnés */
  useEffect(() => {
    if (filteredLots && programmeDatas && programmeDatas?.name) {
      setSelectedLots(filteredLots.map((filteredLot) => filteredLot.name));
    }
  }, [filteredLots, programmeDatas]);

  /* gestion des sockets */
  useEffect(() => {
    if (selectedLots && socketID)
      setSocketsSearchImagesVisible(selectedLots, socketID);
    return () => {
      if (socketID) setSocketsSearchImagesVisible([], socketID);
    };
  }, [selectedLots, socketID]);

  useEffect(() => {
    if (ecranLot && socketID) setSocketsSearchSelected(ecranLot, socketID);
    return () => {
      if (socketID) setSocketsSearchSelected("", socketID);
    };
  }, [ecranLot, socketID]);

  /* Gestion des erreurs API */
  useEffect(() => {
    if (errorLots || errorDatas) {
      setIsError(apiError);
    }
  }, [errorLots, errorDatas, apiError]);

  /* Check/uncheck tous */
  useEffect(() => {
    setSelectedLots(
      allChecked ? filteredLots.map((filteredLot) => filteredLot.name) : []
    );
  }, [allChecked, filteredLots]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const allClick = () => {
    setAllChecked(!allChecked);
  };

  const updateSelectedLots = (lotName, checked) => {
    setSelectedLots(
      checked
        ? [...selectedLotsRef.current, lotName]
        : selectedLotsRef.current.filter((lot) => lot !== lotName)
    );
  };

  const setSelectedLots = (data) => {
    selectedLotsRef.current = data;
    _setSelectedLots(data);
  };

  const videoRemote = ({ exists }) => {
    if (!exists) setIsError(videoEmpty);
  };

  const updateEcranLot = (lotName, selected) => {
    setEcranLot(selected ? lotName : null);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton
        text={
          isError || !filteredLots
            ? searchNew
            : `${filteredLots.length} ${searchQuantity}`
        }
        link={`/project/${projectID}/scale/${scaleID}/search/form${search}`}
      />
      <div className="results | mb-6 px-1">
        {!isError ? (
          <>
            {filteredLots.length > 0 ? (
              <>
                <div onClick={allClick} className="lot | mb-8 p-4">
                  <div className="lot__body">
                    <CheckedIcon checked={allChecked} />
                    <input
                      type="checkbox"
                      checked={allChecked}
                      onChange={() => {}}
                    />
                    <div>{searchAll}</div>
                  </div>
                </div>

                <ul className="grid md:grid-cols-3 gap-8">
                  {filteredLots.map((lot) => (
                    <li key={lot.name}>
                      <Lot
                        lot={lot}
                        allChecked={allChecked}
                        ecranLot={ecranLot}
                        updateEcranLot={updateEcranLot}
                        updateSelectedLots={updateSelectedLots}
                        programmeDatas={programmeDatas}
                        projectID={projectID}
                        scaleID={scaleID}
                      />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <p className="text-center">{searchNull}</p>
            )}
          </>
        ) : (
          <Empty text={isError} />
        )}
      </div>
      <SearchButton projectID={projectID} scaleID={scaleID} />
    </>
  );
};

export default Results;
