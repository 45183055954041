/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createSlice } from "@reduxjs/toolkit";
import { setContent } from "./content.actions";

/*//////////////////////*/
/* Initial values
/*/ /////////////////////*/
export const INITIAL_STATE_CONTENT = {
  loading: false,
  texts: [],
  error: false,
  success: false,
};

/*//////////////////////*/
/* Slice
/*/ /////////////////////*/
export const contentSlice = createSlice({
  name: "content",
  initialState: INITIAL_STATE_CONTENT,
  extraReducers: (builder) => {
    builder
      .addCase(setContent.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setContent.fulfilled, (state, { payload }) => {
        if (payload?.data && payload.data != null) {
          state.loading = false;
          state.success = true;
          state.texts = payload.data.attributes;
          state.error = false;
        } else {
          state.loading = false;
          state.success = false;
          state.error = true;
        }
      })
      .addCase(setContent.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const contentReducer = contentSlice.reducer;
