export const EyeIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 85.9 45.6"
  >
    <path d="M44.6 11.9c0-1.1.2-2.2.6-3.2-.7-.1-1.5-.2-2.3-.2-7.6 0-13.8 6.2-13.8 13.8s6.2 13.8 13.8 13.8c7.6 0 13.8-6.2 13.8-13.8 0-.6-.1-1.2-.1-1.8-.9.3-1.9.5-2.9.5-5 0-9.1-4.1-9.1-9.1z" />
    <path d="M42.9 45.6c-15.5 0-29.9-6.1-40.7-17.2-3-3.1-3-8.1 0-11.1C13 6.1 27.5 0 42.9 0c15.5 0 29.9 6.1 40.7 17.2 3 3.1 3 8.1 0 11.1-10.7 11.2-25.2 17.3-40.7 17.3zm0-40.6C28.8 5 15.7 10.6 5.8 20.7c-1.1 1.2-1.1 3 0 4.2 9.8 10.1 23 15.7 37.1 15.7S70.2 35 80 24.9c1.1-1.2 1.1-3 0-4.2C70.2 10.6 57 5 42.9 5z" />
  </svg>
);
