export const ArrowLeft = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 21.8 15.6"
  >
    <path
      className="fill-texts"
      d="M0 7.8c0 .1 0 .2.1.3 0 .1.1.2.2.2l7.1 7.1c.3.3.7.3 1 0s.3-.7 0-1l-6-5.9h18.7c.4 0 .7-.3.7-.7s-.3-.7-.7-.7H2.4l5.9-5.9c.3-.3.3-.7 0-1C8.2.1 8 0 7.8 0s-.4.1-.5.2L.2 7.3c-.1.1-.1.1-.1.2-.1.1-.1.2-.1.3z"
    />
  </svg>
);
