const JoystickIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 274 274"
    >
      <path d="M226.5 89.5h-42v-42C184.5 21.3 163.2 0 137 0S89.5 21.3 89.5 47.5v42h-42C21.3 89.5 0 110.8 0 137s21.3 47.5 47.5 47.5h42v42c0 26.2 21.3 47.5 47.5 47.5s47.5-21.3 47.5-47.5v-42h42c26.2 0 47.5-21.3 47.5-47.5s-21.3-47.5-47.5-47.5z" />
    </svg>
  );
};

export default JoystickIcon;
