/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { EyeIcon } from "../../icons/Eye.icon";

const QRCodeForm = ({ onFormSubmit }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { register, handleSubmit, reset } = useForm();
  const {
    texts: { qrcodeUserName, qrcodePassword, qrcodeButton },
  } = useSelector(getContentReducer);
  const [passwordVisible, setPasswordVisible] = useState(false);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const onSubmit = (fields) => {
    onFormSubmit(fields);
    reset();
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <form className="my-10 relative" onSubmit={handleSubmit(onSubmit)}>
      <label>
        <input
          type="text"
          placeholder={qrcodeUserName}
          {...register("email", { required: true })}
        />
      </label>
      <label className="relative">
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder={qrcodePassword}
          {...register("password", { required: true })}
        />
        <div
          className="absolute top-0 right-0 p-3 w-12 h-full flex items-center cursor-pointer"
          onMouseDown={() => setPasswordVisible(true)}
          onMouseUp={() => setPasswordVisible(false)}
          onTouchStart={() => setPasswordVisible(true)}
          onTouchEnd={() => setPasswordVisible(false)}
        >
          <EyeIcon />
        </div>
      </label>
      <button type="submit">{qrcodeButton}</button>
    </form>
  );
};

export default QRCodeForm;
