import "./Empty.styles.scss";

const Empty = ({ text }) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return <p className="empty" dangerouslySetInnerHTML={{ __html: text }} />;
};

export default Empty;
