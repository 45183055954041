export const SearchIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24.6 24.6"
  >
    <path
      className="fill-white"
      d="m24.6 23.2-4.8-5.1c1.6-1.9 2.5-4.4 2.5-7C22.3 5 17.3 0 11.1 0S0 5 0 11.1s5 11.1 11.1 11.1c2.8 0 5.3-1 7.3-2.7l4.8 5 1.4-1.3zm-13.5-2.9c-5 0-9.1-4.1-9.1-9.1S6.1 2 11.1 2s9.1 4.1 9.1 9.1-4 9.2-9.1 9.2z"
    />
  </svg>
);
