/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStrapiContent } from "../../utils/strapi.utils";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/
export const setContent = createAsyncThunk(
  "content/setContent",
  async (_, { rejectWithValue }) => {
    try {
      return getStrapiContent();
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);
