/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getContentReducer } from "../../store/content/content.selector";
import { getProgrammeReducer } from "../../store/programme/programme.selector";
import BackButton from "../../components/BackButton/BackButton.component";
import SearchForm from "../../components/SearchForm/SearchForm.component";
import Empty from "../../components/Empty/Empty.component";

const Search = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { projectID, scaleID } = useParams();
  const {
    texts: { searchNew, apiError, videoEmpty },
  } = useSelector(getContentReducer);
  const { errorLots, errorDatas } = useSelector(getProgrammeReducer);
  const [isError, setIsError] = useState(false);
  const socketsReady = useRef(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("videoRemote", videoRemote);
      socketsReady.current = true;
    }
    return () => {
      if (socket) socket.off("videoRemote", videoRemote);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /* gestion des erreurs API */
  useEffect(() => {
    if (errorLots || errorDatas) {
      setIsError(apiError);
    }
  }, [errorLots, errorDatas, apiError]);

  /*//////////////////////*/
  /* Fonctions
  /*/ /////////////////////*/
  const videoRemote = ({ exists }) => {
    if (!exists) setIsError(videoEmpty);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton
        text={searchNew}
        link={`/project/${projectID}/scale/${scaleID}`}
      />
      {!isError ? <SearchForm /> : <Empty text={isError} />}
    </>
  );
};

export default Search;
