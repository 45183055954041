/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { encrypt } from "../../utils/encrypt.utils";
import { getContentReducer } from "../../store/content/content.selector";
import QRCode from "qrcode";
import QRCodeForm from "../../components/QRCodeForm/QRCodeForm.component";

const QRCodePage = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const [qr, setQr] = useState("");
  const { isAdmin } = useSelector(getAuthReducer);
  const {
    texts: { qrcodeDownload },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/
  const onFormSubmit = (fields) => {
    /* Console URL */
    console.log(
      `${process.env.REACT_APP_PUBLIC_URL}/qrcode-signin?u=${encrypt(
        fields.email,
        process.env.REACT_APP_CRYPT
      )}&p=${encrypt(fields.password, process.env.REACT_APP_CRYPT)}`
    );

    /* Generate QR Code */
    QRCode.toDataURL(
      `${process.env.REACT_APP_PUBLIC_URL}/qrcode-signin?u=${encrypt(
        fields.email,
        process.env.REACT_APP_CRYPT
      )}&p=${encrypt(fields.password, process.env.REACT_APP_CRYPT)}`,
      {
        width: 800,
        margin: 2,
      },
      (err, URL) => {
        if (err) return console.error(err);
        setQr(URL);
      }
    );
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return !isAdmin ? (
    <Navigate to="/" replace />
  ) : (
    <div className="height-full flex flex-wrap justify-center items-center">
      <div className="w-full max-w-sm text-center py-10 button-parent">
        {qr && (
          <>
            <img alt="QR Code" src={qr} />
            <a color="success" href={qr} download="qrcode.png">
              <button className="button">{qrcodeDownload}</button>
            </a>
          </>
        )}
        <QRCodeForm onFormSubmit={onFormSubmit} />
      </div>
    </div>
  );
};

export default QRCodePage;
