import { useDispatch, useSelector } from "react-redux";
import { setLot } from "../store/lot/lot.actions";
import { resetLot } from "../store/lot/lot.reducer";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { getProjectsReducer } from "../store/projects/projects.selector";
import { setSocketsSearchBackground } from "../utils/websockets.utils";
import { getScale } from "../utils/helpers.utils";
import { getAuthReducer } from "../store/auth/auth.selector";

/* Routes de lot */
export const LotRoute = ({ userToken }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { projectID, scaleID, lotID } = useParams();
  const { socketID } = useSelector(getAuthReducer);
  const projectsReducer = useSelector(getProjectsReducer);
  const currentScale = useMemo(
    () => getScale(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(setLot({ lotID, projectID, userToken }));
    return () => dispatch(resetLot());
    // eslint-disable-next-line
  }, []);

  /* envoi images de fond */
  useEffect(() => {
    if (
      pathname.split("/").includes("search") &&
      currentScale?.searchBackgroundImage
    ) {
      setSocketsSearchBackground(null, socketID);
    }

    return () => {
      if (
        currentScale?.searchBackgroundImage &&
        socketID &&
        pathname.split("/").includes("search")
      ) {
        setSocketsSearchBackground(
          currentScale.searchBackgroundImage,
          socketID
        );
      }
    };
  }, [currentScale, socketID, pathname]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return <Outlet />;
};
