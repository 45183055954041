export const RotateIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 204 161.6"
    xmlSpace="preserve"
  >
    <linearGradient
      id="gradient"
      gradientUnits="userSpaceOnUse"
      x1="136.296"
      y1="130.385"
      x2="57.748"
      y2="3.56"
    >
      <stop offset="0" style={{ stopColor: "#e57" }} />
      <stop offset="1" style={{ stopColor: "#1949dd" }} />
    </linearGradient>
    <path
      style={{ fill: "url(#gradient)" }}
      d="M135 148.6H68c-8.3 0-15.1-6.7-15.1-15.1V15.1C53 6.7 59.7 0 68 0h67c8.3 0 15.1 6.7 15.1 15.1v118.4c0 8.3-6.7 15.1-15.1 15.1z"
    />
    <path
      className="fill-white"
      d="M134.5 124.2H68.6c-2.4 0-4.4-2-4.4-4.4V17.4c0-2.4 2-4.4 4.4-4.4h65.9c2.4 0 4.4 2 4.4 4.4v102.4c0 2.4-2 4.4-4.4 4.4z"
    />
    <circle className="fill-white" cx="101.5" cy="137.3" r="4.9" />
    <path d="M33.4 42.5c-.2-1.2-1.4-2.1-2.6-1.9l-16.4 2.6c-1.2.2-2.1 1.4-1.9 2.6.2 1.2 1.4 2.1 2.6 1.9l9.6-1.5c-16.4 18.5-17.1 46.9-.5 66.2.5.5 1.1.8 1.7.8.5 0 1.1-.2 1.5-.6 1-.8 1.1-2.3.2-3.2-15.5-18.1-14.4-45 1.9-61.7l1.9 11.9c.2 1.1 1.2 1.9 2.3 1.9h.4c1.2-.2 2.1-1.4 1.9-2.6l-2.6-16.4zM188.5 106l-9.6 1.5c16.4-18.5 17.1-46.9.5-66.2-.8-1-2.3-1.1-3.2-.2-1 .8-1.1 2.3-.2 3.2 15.5 18.1 14.4 45-1.9 61.7l-1.9-11.9c-.2-1.2-1.4-2.1-2.6-1.9-1.2.2-2.1 1.4-1.9 2.6l2.6 16.4c.1.6.4 1.1.9 1.5.4.3.9.4 1.3.4h.4l16.4-2.6c1.3-.2 2.1-1.4 1.9-2.6-.3-1.2-1.5-2.1-2.7-1.9z" />
  </svg>
);
