export const EtageIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M39.2 36.9h45.9c1.1 0 2-.9 2-2s-.9-2-2-2H39.2c-1.1 0-2 .9-2 2s.9 2 2 2zM85.1 50.3H39.2c-1.1 0-2 .9-2 2s.9 2 2 2h45.9c1.1 0 2-.9 2-2s-.9-2-2-2zM85.1 67.6H39.2c-1.1 0-2 .9-2 2s.9 2 2 2h45.9c1.1 0 2-.9 2-2s-.9-2-2-2zM85.1 84.9H39.2c-1.1 0-2 .9-2 2s.9 2 2 2h45.9c1.1 0 2-.9 2-2s-.9-2-2-2z"
    />
  </svg>
);
