export const CheckedIcon = ({ checked, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 32.3 32.3"
    className={`${checked ? "checked--true" : "checked--false"}`}
  >
    <path
      className={`checked__background ${
        checked ? "fill-primary" : "fill-white"
      }`}
      d="M1 28V4.3C1 2.5 2.5 1 4.3 1H28c1.8 0 3.3 1.5 3.3 3.3V28c0 1.8-1.5 3.3-3.3 3.3H4.3C2.5 31.3 1 29.9 1 28z"
    />
    <path
      className="checked__arrow fill-white"
      d="M15 25c-.7 0-1.4-.3-1.9-.9l-6-7c-.9-1.1-.8-2.6.3-3.5 1-.9 2.6-.8 3.5.3l3.8 4.5 7.8-11.8c.8-1.3 2.4-1.6 3.5-.8 1.2.8 1.5 2.3.7 3.5L17 23.8c-.4.7-1.2 1.1-1.9 1.1 0 .1-.1.1-.1.1z"
    />
    <path
      className="fill-primary"
      d="M28 32.3H4.4C2 32.3 0 30.4 0 28V4.2C0 1.8 2-.2 4.4-.2H28c2.4 0 4.4 2 4.4 4.4V28c-.1 2.4-2 4.3-4.4 4.3zM4.4 1.9C3.1 1.9 2 2.9 2 4.2V28c0 1.3 1.1 2.4 2.4 2.4H28c1.3 0 2.4-1.1 2.4-2.4V4.2c0-1.3-1.1-2.4-2.4-2.4H4.4z"
    />
  </svg>
);
