/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContactIcon } from "../../icons/Contact.icon";
import { getContentReducer } from "../../store/content/content.selector";
import "./Footer.styles.scss";
import { HooppIcon } from "../../icons/Hoopp.icon";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { useRef } from "react";
import { logoutUser } from "../../utils/websockets.utils";
import { logout } from "../../store/auth/auth.reducer";
import { emptyProjects } from "../../store/projects/projects.reducer";

const Footer = ({ contact }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const {
    texts: { contactAccess },
  } = useSelector(getContentReducer);
  const dispatch = useDispatch();
  const { socketID } = useSelector(getAuthReducer);
  const canLogout = useRef(false);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const logOut = async () => {
    (await socketID) && logoutUser(socketID);
    dispatch(logout());
    dispatch(emptyProjects());
  };

  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/
  const onButtonDown = () => {
    canLogout.current = true;
    setTimeout(() => {
      if (canLogout.current) logOut();
    }, 3000);
  };

  const onButtonLeave = () => {
    canLogout.current = false;
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <footer
      className={`footer | fixed bottom-0 left-0 w-full text-center px-6 py-3 z-40 flex ${
        contact ? "justify-between" : "justify-center"
      }`}
    >
      <button
        onTouchStart={onButtonDown}
        onTouchEnd={onButtonLeave}
        onMouseDown={onButtonDown}
        onMouseLeave={onButtonLeave}
      >
        <HooppIcon className="w-14" />
      </button>

      {contact && (
        <Link
          to="/contact"
          className="footer__link | flex items-center border-b-black"
        >
          <ContactIcon className="w-5 mr-2" />
          <span>{contactAccess}</span>
        </Link>
      )}
    </footer>
  );
};

export default Footer;
