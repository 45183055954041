/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLots, getProgrammeDatas } from "../../utils/virtualbuilding.utils";

/*//////////////////////*/
/* Exports
/*/ /////////////////////*/

export const setProgrammeDatas = createAsyncThunk(
  "projects/setProgrammeDatas",
  async ({ programmeName }, { rejectWithValue }) => {
    try {
      return getProgrammeDatas(programmeName);
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

export const setLots = createAsyncThunk(
  "projects/setLots",
  async (
    {
      programmeName,
      searchBatiment,
      searchDetails,
      searchPrintOnly,
      printLots,
    },
    { rejectWithValue }
  ) => {
    try {
      return getLots(
        programmeName,
        searchBatiment,
        searchDetails,
        searchPrintOnly,
        printLots
      );
    } catch (err) {
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);
