export const ImageIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 162.1 134.1"
  >
    <path
      className="fill-primary"
      d="M158.5 21.4c-2.9-3.8-7.1-6.3-11.9-7L46.2.2c-9.8-1.4-18.9 5.5-20.3 15.3l-3.2 22.4H18c-9.9 0-18 8.1-18 18v60.3c0 9.9 8.1 18 18 18h101.5c9.9 0 18-8.1 18-18v-6.4c3.3-.3 6.4-1.5 9.1-3.5 3.8-2.9 6.3-7.1 7-11.8l8.4-59.7c.6-4.9-.6-9.6-3.5-13.4zm-28.1 94.7c0 6.1-4.9 11-11 11H18c-6.1 0-11-4.9-11-11V55.8c0-6.1 4.9-11 11-11h101.5c6.1 0 11 4.9 11 11v60.3zM155 33.7l-8.4 59.7c-.4 2.9-1.9 5.5-4.3 7.2-1.5 1.1-3.1 1.8-4.9 2.1V55.8c0-9.9-8.1-18-18-18H29.8l3-21.4c.8-6 6.4-10.2 12.4-9.3l100.5 14.2c2.9.4 5.5 1.9 7.2 4.3s2.5 5.2 2.1 8.1z"
    />
    <path
      className="fill-primary"
      d="M34 81c8.2 0 14.8-6.6 14.8-14.8 0-8.2-6.6-14.8-14.8-14.8S19.2 58 19.2 66.2C19.2 74.4 25.8 81 34 81zm0-22.6c4.3 0 7.8 3.5 7.8 7.8S38.3 74 34 74s-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8zM93.2 64.4c-.6-1-1.7-1.6-2.9-1.7-1.2 0-2.3.5-3 1.5L63.1 98.9 47.3 87.6c-1.4-1-3.3-.8-4.5.4l-24.3 24c-1 1-1.3 2.5-.8 3.8.5 1.3 1.8 2.2 3.2 2.2H120c1.3 0 2.4-.7 3.1-1.8.6-1.1.6-2.5-.1-3.5L93.2 64.4zM29.5 111l16.2-16 16.2 11.6c.8.5 1.7.8 2.6.6.9-.2 1.7-.7 2.3-1.4L90 72.6l23.8 38.4H29.5z"
    />
  </svg>
);
