/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getContentReducer } from "../../store/content/content.selector";
import { getLotReducer } from "../../store/lot/lot.selector";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton.component";
import Loader from "../../components/Loader/Loader.component";
import Empty from "../../components/Empty/Empty.component";
import Element from "../../components/Element/Element.component";
import VideoPrestation from "../../components/Video/Prestation/VideoPrestation.component";

const Piece = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { pathname } = useLocation();
  const { projectID, scaleID, lotID, pieceID } = useParams();
  const { loading, pieces } = useSelector(getLotReducer);
  const {
    texts: { videosEmpty },
  } = useSelector(getContentReducer);

  /* url de retour */
  const returnPath = pathname.split("/").includes("plan")
    ? `/project/${projectID}/plan/${lotID}/pieces`
    : `/project/${projectID}/scale/${scaleID}/search/results/${lotID}/pieces`;

  const piece = useMemo(
    () => pieces.find((piece) => piece.id === +pieceID),
    [pieces, pieceID]
  );

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton text={piece?.name ? piece.name : ""} link={returnPath} />
      <div>
        {loading ? (
          <div className="relative h-56">
            <Loader />
          </div>
        ) : (
          <>
            {piece?.prestations.length > 0 ? (
              <ul>
                {piece.prestations.map((prestation) => (
                  <li key={prestation.id}>
                    <Element
                      text={prestation.name}
                      link={`prestation/${prestation.id}`}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {piece?.file ? (
                  <VideoPrestation informations={piece} socket={socket} />
                ) : (
                  <Empty text={videosEmpty} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Piece;
