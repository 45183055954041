/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { getContentReducer } from "../../store/content/content.selector";
import { emptyVideo, setSocketsForceRoom } from "../../utils/websockets.utils";
import { setLastPage } from "../../store/history/history.reducer";
import { useEffect } from "react";

const Already = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socketID } = useSelector(getAuthReducer);
  const {
    texts: { alreadyError, alreadyButton },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(setLastPage("already"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  const forceRoom = () => {
    setSocketsForceRoom(socketID);
    emptyVideo(socketID);
    navigate("/");
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="height-full flex justify-center items-center">
      <div className="max-w-md w-10/12 form">
        <p className="mb-8 text-center">{alreadyError}</p>
        <button onClick={forceRoom}>{alreadyButton}</button>
      </div>
    </div>
  );
};

export default Already;
