/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { getAuthReducer } from "../../store/auth/auth.selector";
import Title from "../../components/Title/Title.component";
import Message from "../../components/Message/Message.component";
import ContactForm from "../../components/ContactForm/ContactForm.component";

const Contact = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*
  const {
    texts: { contactTitle, contactSuccess, contactError },
  } = useSelector(getContentReducer);
  const { userToken } = useSelector(getAuthReducer);
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if (socket) {
      socket.on("emailSuccess", emailSuccess);
      socket.off("emailError", emailError);
    }
    return () => {
      if (socket) {
        socket.off("emailSuccess", emailSuccess);
        socket.off("emailError", emailError);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const emailSuccess = () => setSucces(true);
  const emailError = () => setError(true);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="form relative flex justify-center items-center">
      <div>
        {userToken && (
          <div className="sticky top-0 flex justify-center items-center pt-6 bg-white z-50">
            <Title text={contactTitle} />
          </div>
        )}
        <div className="max-w-md w-full pt-6 mx-auto">
          {!success && !error && <ContactForm />}
          {success && (
            <Message
              className="text-center text-base not-italic"
              text={contactSuccess}
            />
          )}
          {error && (
            <Message
              className="text-center text-base not-italic"
              type="error"
              text={contactError}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
