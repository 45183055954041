/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getContentReducer } from "../../store/content/content.selector";
import { getProgrammeReducer } from "../../store/programme/programme.selector";
import { parameters } from "../../utils/virtualbuilding.utils";
import { getProjectsReducer } from "../../store/projects/projects.selector";
import { getScale, getSearchOption } from "../../utils/helpers.utils";
import Select from "react-select";
import Loader from "../Loader/Loader.component";
import "./SearchForm.styles.scss";

const SearchForm = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const projectsReducer = useSelector(getProjectsReducer);
  const { programmeDatas } = useSelector(getProgrammeReducer);
  const { projectID, scaleID } = useParams();
  const [loading, setLoading] = useState(true);
  const [floors, setFloors] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [orientations, setOrientations] = useState(null);
  const [options, setOptions] = useState(null);
  const [searchs, setSearchs] = useState([]);
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const {
    texts: {
      searchFloor,
      searchRoom,
      searchOrientation,
      searchOptions,
      searchSurface,
      searchPrice,
      searchButton,
    },
  } = useSelector(getContentReducer);
  const { searchBatiment } = useMemo(
    () => getScale(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /* Set details */
  useEffect(() => {
    if (programmeDatas) {
      setSearchs(programmeDatas.pagesDetails.rechercher);

      /* Étages */
      setFloors(
        programmeDatas.maquette.floors.map((floor) => ({
          value: floor,
          label: floor,
        }))
      );

      /* Pièces */
      setRooms(
        programmeDatas.lots.global.typologies.map((room) => ({
          value: room,
          label: room,
        }))
      );

      /* Options */
      setOptions(
        programmeDatas.pagesDetails.rechercher.optionList.map((option) => ({
          value: option.value,
          label: option.value,
        }))
      );

      /* Orientations */
      setOrientations([
        { label: "Nord", value: "N" },
        { label: "Ouest", value: "O" },
        { label: "Sud", value: "S" },
        { label: "Est", value: "E" },
      ]);

      /* loading */
      setLoading(false);
    }
  }, [programmeDatas]);

  /* Disable si trop lent */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false);
        // setError(true);
      }
    }, 6000);
    return () => clearTimeout(timer);
  }, [loading]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const onSubmit = async (fields) => {
    setLoading(true);
    const infos = [{ name: "batiments", value: searchBatiment }];
    for (let parameter of parameters) {
      if (fields[parameter]) {
        infos.push({
          name: parameter,
          value: Array.isArray(fields[parameter])
            ? fields[parameter].join(",")
            : fields[parameter],
        });
      }
    }

    /* Navigation avec paramètres */
    navigate(
      `/project/${projectID}/scale/${scaleID}/search/results?${infos
        .map((info) => `${info.name}=${info.value}`)
        .join("&")}`
    );
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      {loading ? (
        <div className="relative h-56">
          <Loader />
        </div>
      ) : (
        <form className="searchForm relative" onSubmit={handleSubmit(onSubmit)}>
          {searchs.floorFilter && floors && (
            <>
              <label htmlFor="">{searchFloor} :</label>
              <div className="inputParent selectParent selector">
                <Controller
                  control={control}
                  name="etages"
                  defaultValue={searchParams.get("etages")}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      noOptionsMessage={() => "Aucun étage disponible"}
                      placeholder="Choisir ..."
                      value={floors.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={floors}
                      isMulti
                      defaultValue={getSearchOption(
                        searchParams.get("etages"),
                        floors
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}

          {searchs.roomFilter && rooms && (
            <>
              <label htmlFor="">{searchRoom} :</label>
              <div className="inputParent selectParent selector">
                <Controller
                  control={control}
                  name="pieces"
                  defaultValue={searchParams.get("pieces")}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      noOptionsMessage={() => "Aucune typologie disponible"}
                      placeholder="Choisir ..."
                      value={rooms.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={rooms}
                      isMulti
                      defaultValue={getSearchOption(
                        searchParams.get("pieces"),
                        rooms
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}

          {searchs.optionsFilter && options && (
            <>
              <label htmlFor="">{searchOptions} :</label>
              <div className="inputParent selectParent selector">
                <Controller
                  control={control}
                  name="options"
                  defaultValue={searchParams.get("options")}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      noOptionsMessage={() => "Aucune option disponible"}
                      placeholder="Choisir ..."
                      value={options.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={options}
                      isMulti
                      defaultValue={getSearchOption(
                        searchParams.get("options"),
                        options
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}

          {searchs.orientationFilter && orientations && (
            <>
              <label htmlFor="">{searchOrientation} :</label>
              <div className="inputParent selectParent selector">
                <Controller
                  control={control}
                  name="orientations"
                  defaultValue={searchParams.get("orientations")}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      noOptionsMessage={() => "Aucune orientation disponible"}
                      placeholder="Choisir ..."
                      value={orientations.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      options={orientations}
                      isMulti
                      defaultValue={getSearchOption(
                        searchParams.get("orientations"),
                        orientations
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}

          {searchs.surfaceFilter && (
            <>
              <label htmlFor="">{searchSurface} :</label>
              <div className="inputParent | flex gap-x-4 gap-y-0">
                <input
                  {...register("minSurface")}
                  className="mb-0"
                  type="number"
                  placeholder="Min."
                  defaultValue={searchParams.get("minSurface")}
                />
                <input
                  {...register("maxSurface")}
                  className="mb-0"
                  type="number"
                  placeholder="Max."
                  defaultValue={searchParams.get("maxSurface")}
                />
              </div>
            </>
          )}

          {searchs.prixFilter && (
            <>
              <label htmlFor="">{searchPrice} :</label>
              <div className="inputParent | flex gap-x-4 gap-y-0">
                <input
                  {...register("minPrix")}
                  className="mb-0"
                  type="number"
                  placeholder="Min."
                  defaultValue={searchParams.get("minPrix")}
                />
                <input
                  {...register("maxPrix")}
                  className="mb-0"
                  type="number"
                  placeholder="Max."
                  defaultValue={searchParams.get("maxPrix")}
                />
              </div>
            </>
          )}

          <button type="submit" className="py-4">
            {searchButton}
          </button>
        </form>
      )}
    </>
  );
};

export default SearchForm;
