export const OptionsIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M89.8 60H64V34.2c0-1.1-.9-2-2-2s-2 .9-2 2V60H34.2c-1.1 0-2 .9-2 2s.9 2 2 2H60v25.8c0 1.1.9 2 2 2s2-.9 2-2V64h25.8c1.1 0 2-.9 2-2s-.9-2-2-2z"
    />
  </svg>
);
