/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "./root-reducer";
// import logger from "redux-logger";

// const middleWares = [process.env.NODE_ENV === "development" && logger].filter(
//   Boolean
// );

// export const store = configureStore({
//   reducer: persistStore,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({ serializableCheck: false }).concat(middleWares),
// });

export const store = configureStore({
  reducer: persistStore,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
