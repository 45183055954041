import { useEffect, useRef, useState } from "react";
import { Reload } from "../../../icons/Reload.icon";
import "./VideoChapter.styles.scss";
import { getMinutesFromSeconds } from "../../../utils/helpers.utils";

const VideoChapter = ({
  chapter: { name, seconds, end },
  onChapterUpdate,
  isActiveChapter,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const timer = useRef(null);
  const [ended, setEnded] = useState(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if (end) timer.current = parseFloat(Number(end - seconds).toFixed(2));
  }, [end, seconds]);

  /*//////////////////////*/
  /* Functions 
  /*/ /////////////////////*/
  const onClickChapter = (seconds) => {
    setEnded(false);
    onChapterUpdate(seconds, timer.current);
    setTimeout(() => {
      if (isActiveChapter) setEnded(true);
    }, timer.current * 1000);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div
      onClick={() => onClickChapter(seconds)}
      className={`chapter block bg-primary text-white text-center uppercase rounded-md mt-4 p-4 flex justify-between ${
        isActiveChapter && "chapter--active"
      }`}
    >
      <p>
        {name}
        {timer.current && (
          <i className="opacity-50 lowercase">
            {getMinutesFromSeconds(timer.current)}
          </i>
        )}
      </p>
      {isActiveChapter && ended && (
        <button className="w-8">
          <Reload color="white" />
        </button>
      )}
    </div>
  );
};

export default VideoChapter;
