/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getContentReducer } from "../../store/content/content.selector";
import {
  getProjects,
  getProjectsReducer,
  getVideos,
} from "../../store/projects/projects.selector";
import {
  setSocketsAutoPlay,
  setSocketsAutoStop,
  setSocketsLogoURL,
  setSocketsNewScaleReady,
} from "../../utils/websockets.utils";
import BackButton from "../../components/BackButton/BackButton.component";
import Element from "../../components/Element/Element.component";
import Empty from "../../components/Empty/Empty.component";
import { useEffect, useMemo, useState } from "react";
import { setLastPage } from "../../store/history/history.reducer";
import { PlayPause } from "../../icons/PlayPause.icon";
import { getAuthReducer } from "../../store/auth/auth.selector";
import SearchButton from "../../components/SearchButton/SearchButton.component";
import Loader from "../../components/Loader/Loader.component";
import {
  getAutoplayVideos,
  getMinutesFromSeconds,
  getPlan,
  getProjectsRoutes,
  getScale,
  getScaleVideos,
} from "../../utils/helpers.utils";
import "./Videos.styles.scss";
import {
  setCurrentProject,
  setCurrentScale,
} from "../../store/projects/projects.reducer";

const Videos = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { projectID, scaleID } = useParams();
  const {
    texts: { textVideos, videosEmpty, videoEmpty },
  } = useSelector(getContentReducer);
  const { singleProject, socketID, logoUrl } = useSelector(getAuthReducer);
  const { loading, currentScale, currentProject } =
    useSelector(getProjectsReducer);
  const projectsReducer = useSelector(getProjectsReducer);
  const projects = useSelector(getProjects);
  const videosRoutes = useSelector(getVideos);
  /* states et refs */
  const [isPlaying, setIsPlaying] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, isError] = useState(false);
  const [isUniqueScale, setIsUniqueScale] = useState(false);
  const [isUniqueScaleMultipleProjects, setIsUniqueScaleMultipleProjects] =
    useState(false);

  /* memos */
  const plan = useMemo(
    () => getPlan(projectsReducer, projectID),
    [projectID, projectsReducer]
  );
  const autoplayVideos = useMemo(
    () => getAutoplayVideos(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );
  const videos = useMemo(
    () => getScaleVideos(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );
  const scale = useMemo(
    () => getScale(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );
  const projectsRoutes = useMemo(
    () => getProjectsRoutes(projects, videosRoutes),
    [projects, videosRoutes]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /* update scale et lancement vidéo correspondante */
  useEffect(() => {
    if (currentScale !== scaleID || currentProject !== projectID) {
      dispatch(setCurrentScale(scaleID));
      dispatch(setCurrentProject(projectID));
      if (scale.isReadyVideo && socketID) {
        setSocketsNewScaleReady(scale.isReadyVideo, socketID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleID, projectID, currentScale, socketID]);

  useEffect(() => {
    dispatch(setLastPage("videos"));
    if (socket) {
      socket.on("videoRemote", videoRemote);
      socket.on("autoPlayNewVideo", autoPlayNewVideo);
    }
    return () => {
      if (socket) {
        socket.off("videoRemote", videoRemote);
        socket.off("autoPlayNewVideo", autoPlayNewVideo);
        socket.emit("autoStop", socketID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (socket) {
      if (isPlaying === true) {
        if (autoplayVideos.length > 0) {
          setSelectedVideo(autoplayVideos[0].id);
          setSocketsAutoPlay(
            autoplayVideos.map((autoplayVideo) => {
              if (
                autoplayVideo.type === "Player" &&
                autoplayVideo.auto === true
              ) {
                return { id: autoplayVideo.id, file: autoplayVideo.file };
              } else {
                return null;
              }
            }),
            socketID
          );
        }
      } else {
        setSelectedVideo(null);
        setSocketsAutoStop(socketID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  /* récupération de l'avatar */
  useEffect(() => {
    if (logoUrl && socket && socketID) {
      setSocketsLogoURL(process.env.REACT_APP_STRAPI + logoUrl, socketID);
    }
  }, [logoUrl, socket, socketID]);

  /* verification si une seule échelle */
  useEffect(() => {
    if (singleProject) {
      const currentProject = projectsRoutes.find(
        (project) => project.id === +projectID
      );
      if (
        currentProject?.scales &&
        currentProject.scales.length === 1 &&
        !plan
      ) {
        if (projectsRoutes?.length === 1) {
          setIsUniqueScale(true);
        } else {
          setIsUniqueScaleMultipleProjects(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsRoutes, singleProject, projectID, scaleID]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const totalAutoplayTime = useMemo(() => {
    const total = videos.reduce(
      (total, video) =>
        video.auto && video?.videoDuration
          ? total + video.videoDuration
          : total,
      0
    );
    return total && total > 0 ? getMinutesFromSeconds(total) : null;
  }, [videos]);

  const onAutoPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const autoPlayNewVideo = (videoId) => {
    setSelectedVideo(videoId);
  };

  const videoRemote = ({ exists, total }) => {
    isError(exists ? false : true);
    if (!exists) {
      setSelectedVideo(null);
    }
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/

  return (
    <>
      <BackButton
        text={textVideos}
        link={isUniqueScaleMultipleProjects ? "/" : `/project/${projectID}`}
        back={!isUniqueScale}
      />
      {autoplayVideos && autoplayVideos.length > 1 && (
        <>
          {error ? (
            <Empty text={videoEmpty} />
          ) : (
            <header className="autoplay relative flex justify-center rounded-md p-6 mb-8">
              <hr className="absolute inset-0 h-full w-full bg-light-primary rounded-md" />
              <button onClick={onAutoPlay} className="relative">
                <PlayPause isPlaying={isPlaying} autoPlayIcon />
              </button>
              {totalAutoplayTime && (
                <span className="autoplay__total | text-primary">
                  {totalAutoplayTime}
                </span>
              )}
            </header>
          )}
        </>
      )}
      {loading ? (
        <div className="relative h-56">
          <Loader />
        </div>
      ) : (
        <>
          {videos && videos.length ? (
            <ul>
              {videos.map((video) => {
                return (
                  !video.onlyAuto && (
                    <li key={video.id}>
                      <Element
                        text={video.name}
                        link={`video/${video.id}`}
                        selected={selectedVideo === video.id ? true : false}
                        autoPlay={
                          video.auto && autoplayVideos.length > 1 ? true : false
                        }
                        duration={video.videoDuration}
                      />
                    </li>
                  )
                );
              })}
            </ul>
          ) : (
            !scale?.search && (
              <li>
                <Empty text={videosEmpty} />
              </li>
            )
          )}

          {scale?.search && (
            <SearchButton projectID={projectID} scaleID={scaleID} />
          )}
        </>
      )}
    </>
  );
};

export default Videos;
