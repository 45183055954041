/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { Link, useLocation } from "react-router-dom";
import { SearchIcon } from "../../icons/Search.icon";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";

const SearchButton = ({ projectID, scaleID }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { pathname, search } = useLocation();
  const {
    texts: { searchNew },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <Link
      to={`/project/${projectID}/scale/${scaleID}/search/form${
        pathname.split("/").includes("search") ? search : ""
      }`}
      className="bg-primary block rounded-md p-4 mb-8 text-center"
    >
      <SearchIcon className="w-6 inline" />
      <p className="inline pl-4 text-white">{searchNew}</p>
    </Link>
  );
};

export default SearchButton;
