/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useRef } from "react";
import { ImageIcon } from "../../icons/Image.icon";

const Image = ({ socket, isError, isLoaded }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const socketsReady = useRef(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("videoRemote", imagesRemote);
      socketsReady.current = true;
    }
    return () => {
      if (socket) socket.off("videoRemote", imagesRemote);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  /* événement videoRemote */
  const imagesRemote = ({ exists }) => {
    isLoaded();
    if (!exists) isError(true);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="w-full">
      <header className="relative flex justify-center rounded-md p-6 h-48">
        <hr className="absolute inset-0 h-full w-full bg-light-primary rounded-md" />
        <ImageIcon className="w-20" />
      </header>
    </div>
  );
};

export default Image;
