export const SurfaceIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M88.5 60c-1.1 0-2 .9-2 2v21.7L40.3 37.5H62c1.1 0 2-.9 2-2s-.9-2-2-2H35.5c-1.1 0-2 .9-2 2V62c0 1.1.9 2 2 2s2-.9 2-2V40.3l46.2 46.2H62c-1.1 0-2 .9-2 2s.9 2 2 2h26.5c1.1 0 2-.9 2-2V62c0-1.1-.9-2-2-2z"
    />
  </svg>
);
