export const CoordinatesIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 86.6 78.4"
  >
    <path d="M43.3 78.4 86.6 0H0z" />
  </svg>
);
