const OrientationLineIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 13 240"
    >
      <path d="M0 0h13v90H0z" />
    </svg>
  );
};

export default OrientationLineIcon;
