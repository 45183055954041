import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { emptyVideo } from "../utils/websockets.utils";
import { resetPieces } from "../store/lot/lot.reducer";
import { getAuthReducer } from "../store/auth/auth.selector";

/* Routes de lot */
export const PiecesRoute = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { socketID } = useSelector(getAuthReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    return () => {
      if (socket) {
        dispatch(resetPieces());
        emptyVideo(socketID);
      }
    };
    // eslint-disable-next-line
  }, [socket, socketID]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return <Outlet />;
};
