export const TypeIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M88.5 33.5h-53c-1.1 0-2 .9-2 2v53c0 1.1.9 2 2 2h53c1.1 0 2-.9 2-2v-53c0-1.1-.9-2-2-2zm-2 40.6H64V37.5h22.5v36.6zM60 37.5v10.1H37.5V37.5H60zM37.5 51.6H60v34.9H37.5V51.6zM64 86.5v-8.4h22.5v8.4H64z"
    />
  </svg>
);
