export const PlayPause = ({ isPlaying, autoPlayIcon = false }) => (
  <svg
    className="icon-large"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 29.7 29.7"
  >
    {isPlaying ? (
      <g>
        <circle className="fill-primary" cx="14.9" cy="14.9" r="14.9" />
        {autoPlayIcon && (
          <path
            className="fill-white opacity-60 origin-center spin"
            d="m15 3.5 1.2-1.2c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-2 2s-.1.1-.1.2c-.1.1-.1.3 0 .4 0 .1.1.1.1.2l2 2c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7L15 4.5c5.6 0 10.2 4.6 10.2 10.3 0 5.7-4.6 10.3-10.3 10.3-5.7 0-10.3-4.6-10.3-10.3 0-4 2.2-7.5 5.8-9.3.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-3.9 2-6.3 5.9-6.3 10.2 0 6.2 5.1 11.3 11.3 11.3 6.2 0 11.3-5.1 11.3-11.3 0-6.2-5-11.2-11.2-11.3z"
          />
        )}
        {autoPlayIcon ? (
          <path
            className="fill-white"
            d="M19.8 9.3H10c-.4 0-.7.3-.7.7v9.8c0 .4.3.7.7.7h9.8c.4 0 .7-.3.7-.7V10c0-.4-.3-.7-.7-.7z"
          />
        ) : (
          <path
            className="fill-white"
            d="M12 7.9c-.6 0-1.1.5-1.1 1.1v11.7c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1V9c.1-.6-.4-1.1-1.1-1.1zM17.7 7.9c-.6 0-1.1.5-1.1 1.1v11.7c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1V9c0-.6-.5-1.1-1.1-1.1z"
          />
        )}
      </g>
    ) : (
      <g>
        <circle className="fill-primary" cx="14.9" cy="14.9" r="14.9" />
        {autoPlayIcon && (
          <path
            className="fill-white opacity-60"
            d="m15 3.5 1.2-1.2c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-2 2s-.1.1-.1.2c-.1.1-.1.3 0 .4 0 .1.1.1.1.2l2 2c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7L15 4.5c5.6 0 10.2 4.6 10.2 10.3 0 5.7-4.6 10.3-10.3 10.3-5.7 0-10.3-4.6-10.3-10.3 0-4 2.2-7.5 5.8-9.3.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-3.9 2-6.3 5.9-6.3 10.2 0 6.2 5.1 11.3 11.3 11.3 6.2 0 11.3-5.1 11.3-11.3 0-6.2-5-11.2-11.2-11.3z"
          />
        )}

        <path
          className="fill-white"
          d="m19.9 14.3-7.3-5.9c-.2-.2-.5-.2-.8-.1-.3.1-.4.4-.4.7v11.7c0 .3.2.6.4.7.1 0 .2.1.3.1.2 0 .3-.1.5-.2l7.3-5.9c.2-.1.3-.4.3-.6 0-.2-.1-.4-.3-.5z"
        />
      </g>
    )}
  </svg>
);
