import CryptoJS from "crypto-js";

export const encrypt = (sentence, key) => {
  const ciphertext = CryptoJS.AES.encrypt(sentence, key).toString();
  const urlSafeCiphertext = encodeURIComponent(ciphertext).replace(/[+=]/g, "");
  return urlSafeCiphertext;
};

export const decrypt = (encrypted, key) => {
  const base64Ciphertext = decodeURIComponent(encrypted);
  const decrypted = CryptoJS.AES.decrypt(base64Ciphertext, key).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
};
