/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { useEffect, useRef } from "react";
import { setLastPage } from "../../store/history/history.reducer";
import { emptyProjects } from "../../store/projects/projects.reducer";
import { resetColors } from "../../utils/colors.utils";
import Login from "../../components/Login/Login.component";

import "./SignIn.styles.scss";
import { setCursor } from "../../store/auth/auth.reducer";

const SignIn = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const canUpdateCursorVisibility = useRef(false);
  const { userToken } = useSelector(getAuthReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    resetColors();
    dispatch(emptyProjects());
    dispatch(setLastPage("signin"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  /* gestion des redirections */
  if (userToken) return <Navigate to="/" replace />;

  /* affichage du curseur */
  const onImageDown = () => {
    canUpdateCursorVisibility.current = true;
    setTimeout(() => {
      if (canUpdateCursorVisibility.current) dispatch(setCursor(true));
    }, 3000);
  };

  const onImageLeave = () => {
    canUpdateCursorVisibility.current = false;
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="sign-in | min-h-screen flex flex-wrap content-between">
      <div className="sign-in__form | w-full flex justify-center items-center">
        <div className="max-w-md w-10/12">
          <img
            src="/logo_HOOPP.png"
            alt="logo HOOPP"
            className="px-6 md:px-12"
            onTouchStart={onImageDown}
            onTouchEnd={onImageLeave}
            onMouseDown={onImageDown}
            onMouseLeave={onImageLeave}
          />
          <Login />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
