export const ContactIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 34.6 24.5"
  >
    <path d="M30.3 0h-26C1.9 0 0 1.9 0 4.3v15.8c0 .5.1 1.1.3 1.5 0 .1.1.2.1.4.7 1.4 2.2 2.4 3.9 2.4h26c2.4 0 4.3-1.9 4.3-4.3V4.3c0-2.4-1.9-4.3-4.3-4.3zm0 1.5c.7 0 1.4.3 1.9.7L17.3 14 2.5 2.2c.5-.4 1.1-.7 1.8-.7h26zm0 21.5h-26c-1 0-1.8-.5-2.3-1.2l8.7-6.8c.3-.3.4-.7.1-1.1-.3-.3-.7-.4-1.1-.1l-8.2 6.5v-16c0-.3.1-.6.1-.9l15.2 12.1c.1.1.3.2.5.2s.3-.1.5-.2L33 3.5c.1.3.1.5.1.8v16.1l-8.5-6.6c-.3-.3-.8-.2-1.1.1-.3.3-.2.8.1 1.1l8.8 6.9c-.4.7-1.2 1.1-2.1 1.1z" />
  </svg>
);
