/*//////////////////////*/
/* Imports
/*//////////////////////*/
import { createSlice } from '@reduxjs/toolkit';


/*//////////////////////*/
/* Initial values
/*//////////////////////*/
export const INITIAL_STATE_HISTORY = {
  lastPage: false,
};


/*//////////////////////*/
/* Slice
/*//////////////////////*/
export const historySlice = createSlice({
  name: 'history',
  initialState: INITIAL_STATE_HISTORY,
  reducers: {
    setLastPage(state, {payload}) {
      state.lastPage = payload;
    },
  },
});

export const { setLastPage } = historySlice.actions;
export const historyReducer = historySlice.reducer;
