/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { sendEmail } from "../../utils/websockets.utils";
import Loader from "../Loader/Loader.component";
import Message from "../Message/Message.component";

const ContactForm = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const {
    texts: {
      inputError,
      contactIntroduction,
      contactName,
      contactCompany,
      contactEmail,
      contactMessage,
      contactButton,
      contactEmailMessage,
    },
  } = useSelector(getContentReducer);
  const [loading, setLoading] = useState(false);
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const onSubmit = (fields) => {
    setLoading(true);
    sendEmail(
      contactEmailMessage,
      `\nnom:  ${fields.name} \n\n entreprise:  ${fields.company} \n\n email: ${fields.email} \n\n ${fields.message} `,
      fields.email
    );
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      {loading ? (
        <div className="relative h-56">
          <Loader />
        </div>
      ) : (
        <form className="relative" onSubmit={handleSubmit(onSubmit)}>
          <legend
            className="text-center mb-6"
            dangerouslySetInnerHTML={{ __html: contactIntroduction }}
          />

          {contactName && (
            <>
              {errors.name && <Message type="error" text={inputError} />}
              <label>
                <input
                  type="text"
                  placeholder={contactName}
                  {...register("name", { required: true })}
                  onClick={() => {
                    clearErrors();
                  }}
                  onKeyDown={() => {
                    clearErrors();
                  }}
                />
              </label>
            </>
          )}

          {contactCompany && (
            <>
              {errors.company && <Message type="error" text={inputError} />}
              <label>
                <input
                  type="text"
                  placeholder={contactCompany}
                  {...register("company", { required: true })}
                  onClick={() => {
                    clearErrors();
                  }}
                  onKeyDown={() => {
                    clearErrors();
                  }}
                />
              </label>
            </>
          )}

          {contactEmail && (
            <>
              {errors.email && <Message type="error" text={inputError} />}
              <label>
                <input
                  type="email"
                  placeholder={contactEmail}
                  {...register("email", { required: true })}
                  onClick={() => {
                    clearErrors();
                  }}
                  onKeyDown={() => {
                    clearErrors();
                  }}
                />
              </label>
            </>
          )}

          {errors.message && <Message type="error" text={inputError} />}
          <label>
            <textarea
              rows="5"
              placeholder={contactMessage}
              {...register("message", { required: true })}
              onClick={() => {
                clearErrors();
              }}
              onKeyDown={() => {
                clearErrors();
              }}
            />
          </label>

          <button type="submit">{contactButton}</button>
        </form>
      )}
    </>
  );
};

export default ContactForm;
