export const HomeIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    xmlSpace="preserve" 
    viewBox="0 0 21.7 26.6"
    style={{width : '40px'}}
    >
    <path 
      className="fill-primary"
      d="M21.7 8 11-.4.3 8.3v14.3c0 2.1 1.7 3.8 3.8 3.8h13.8c2.1 0 3.8-1.7 3.8-3.8v-1.5c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1.5c0 1.4-1.1 2.5-2.5 2.5H4.1c-1.4 0-2.5-1.1-2.5-2.5V9L11 1.4l9.3 7.3v5.8H8.6l3.8-3.8c.3-.3.3-.7 0-1-.3-.3-.7-.3-1 0L6 15.2l5.5 5.5c.1.1.3.2.5.2s.3-.1.5-.2c.3-.3.3-.7 0-1l-3.9-3.9h13.1V8z" 
    />
  </svg>
);
