export const AutoPlay = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24.6 34.8"
  >
    <path
      className="fill-primary"
      d="M12.4 5.4c-.3 0-2.1-.2-4.1.1L11.2 2c.4-.4.3-1.1-.1-1.4S10 .3 9.6.7L4.8 6.5s0 .1-.1.1c0 .1-.1.1-.1.2V7.5c0 .1.1.1.1.2s.1.1.1.2l5.8 4.9c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L7.6 7.6c2.3-.4 4.6-.2 4.6-.2h.1c5.4 0 9.8 4.4 9.8 9.8S17.7 27 12.3 27s-9.8-4.4-9.8-9.8c0-.6-.4-1-1-1s-1 .4-1 1C.5 23.7 5.8 29 12.3 29s11.8-5.3 11.8-11.8c0-6.5-5.3-11.8-11.7-11.8z"
    />
  </svg>
);
