/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { INITIAL_STATE_AUTH, authReducer } from "./auth/auth.reducer";
import {
  INITIAL_STATE_PROJECTS,
  projectsReducer,
} from "./projects/projects.reducer";
import {
  INITIAL_STATE_CONTENT,
  contentReducer,
} from "./content/content.reducer";
import { historyReducer } from "./history/history.reducer";
import {
  INITIAL_STATE_PROGRAMME,
  programmeReducer,
} from "./programme/programme.reducer";
import { INITIAL_STATE_LOT, lotReducer } from "./lot/lot.reducer";
import storage from "redux-persist/lib/storage";
import expireReducer from "redux-persist-expire";

/* Temps d'expiration : 30 jours */
const expireTimer = 30 * 86400;

const rootReducers = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  content: contentReducer,
  history: historyReducer,
  programme: programmeReducer,
  lot: lotReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["history", "lot"],
  transforms: [
    expireReducer("auth", {
      expireSeconds: expireTimer,
      autoExpire: true,
      expiredState: INITIAL_STATE_AUTH,
    }),
    expireReducer("projects", {
      expireSeconds: expireTimer,
      autoExpire: true,
      expiredState: INITIAL_STATE_PROJECTS,
    }),
    expireReducer("content", {
      expireSeconds: expireTimer,
      autoExpire: true,
      expiredState: INITIAL_STATE_CONTENT,
    }),
    expireReducer("programme", {
      expireSeconds: expireTimer,
      autoExpire: true,
      expiredState: INITIAL_STATE_PROGRAMME,
    }),
    expireReducer("lot", {
      expireSeconds: expireTimer,
      autoExpire: true,
      expiredState: INITIAL_STATE_LOT,
    }),
  ],
};

export const persistStore = persistReducer(persistConfig, rootReducers);
