/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../../store/auth/auth.selector";
import {
  setSocketsContentGoToPositionVertical,
  setSocketsNewContentWithInitialValue,
} from "../../../utils/websockets.utils";
import { getContentReducer } from "../../../store/content/content.selector";
import { getLotReducer } from "../../../store/lot/lot.selector";
import { setAngleX, setAngleY } from "../../../store/lot/lot.reducer";
import VideoOther from "../Other/VideoOther.component";
import Empty from "../../Empty/Empty.component";

const VideoPrestation = ({ socket, informations }) => {
  const dispatch = useDispatch();
  const { userID, socketID } = useSelector(getAuthReducer);
  const { angleX, angleY, rotaryNorth, rotaryEast, rotarySouth, rotaryWest } =
    useSelector(getLotReducer);
  const {
    texts: { videoEmpty },
  } = useSelector(getContentReducer);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/

  const video = useMemo(() => {
    if (informations) {
      return {
        type: "Rotary",
        rotaryNorth,
        rotaryEast,
        rotarySouth,
        rotaryWest,
        file: informations.file,
        // accept3D: informations.accept3D,
      };
    }
  }, [informations, rotaryNorth, rotaryEast, rotarySouth, rotaryWest]);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /* set timeout if not loaded */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false);
        setError(true);
      }
    }, 6000);
    return () => clearTimeout(timer);
  }, [loading]);

  /* set video file */
  useEffect(() => {
    loadContent();
    // eslint-disable-next-line
  }, [video, userID]);

  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/
  /* errors & loading*/
  const isError = (error) => {
    if (error) setError(true);
  };

  const isLoaded = () => {
    setLoading(false);
  };

  const loadContent = () => {
    if (video.file) {
      setSocketsNewContentWithInitialValue(
        video.file,
        "Rotary",
        angleX,
        socketID
      );
      setSocketsContentGoToPositionVertical(angleY, socketID);
    }
  };

  const updateAngleX = (newAngleX) => {
    dispatch(setAngleX(newAngleX));
  };

  const updateAngleY = (newAngleY) => {
    dispatch(setAngleY(newAngleY));
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full max-w-sm my-12">
        {error ? (
          <Empty text={videoEmpty} />
        ) : (
          <VideoOther
            video={video}
            socketID={socketID}
            socket={socket}
            isError={isError}
            isLoaded={isLoaded}
            initialAngleX={angleX}
            initialAngleY={angleY}
            updateValueX={updateAngleX}
            updateValueY={updateAngleY}
          />
        )}
      </div>
    </div>
  );
};

export default VideoPrestation;
