export const PrixIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 124 124"
  >
    <circle className="fill-white" cx="62" cy="62" r="62" />
    <path
      className="fill-primary"
      d="M83 77.1c-10 10-26.3 10-36.3 0-3-3-5.1-6.5-6.3-10.3h20.5c1.1 0 2-.9 2-2s-.9-2-2-2H39.4c-.4-2.6-.4-5.3 0-7.9h21.4c1.1 0 2-.9 2-2s-.9-2-2-2H40.4c1.2-3.7 3.3-7.2 6.2-10.1 10-10 26.3-10 36.3 0 .8.8 2 .8 2.8 0 .8-.8.8-2 0-2.8-11.6-11.6-30.4-11.6-42 0-3.7 3.7-6.2 8.2-7.6 12.9h-12c-1.1 0-2 .9-2 2s.9 2 2 2h11.3c-.4 2.6-.4 5.3 0 7.9H24.1c-1.1 0-2 .9-2 2s.9 2 2 2h12.1c1.3 4.8 3.8 9.4 7.6 13.1 5.8 5.8 13.4 8.7 21 8.7s15.2-2.9 21-8.7c.8-.8.8-2 0-2.8s-2-.8-2.8 0z"
    />
  </svg>
);
