/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { removeProjectName } from "../../utils/helpers.utils";

const Title = ({ text, space }) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className={`uppercase font-bold text-xl ${space}`}>
      {text && removeProjectName(text)}
    </div>
  );
};

export default Title;
