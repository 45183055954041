import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { calculateAngle } from "../../../utils/helpers.utils";

gsap.registerPlugin(Draggable);

const VideoRotary2D = ({
  video,
  initialAngleX,
  updateAngleX,
  updateFinalX,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const videorotary = useRef();
  const [coordinates, setCoordinates] = useState(null);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    if (
      video.rotaryNorth &&
      video.rotaryEast &&
      video.rotarySouth &&
      video.rotaryWest
    )
      setCoordinates({
        north: video.rotaryNorth,
        east: video.rotaryEast,
        south: video.rotarySouth,
        west: video.rotaryWest,
      });
  }, [video]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (videorotary.current) {
        gsap.set(".videorotary__rotary", {
          transformOrigin: "center center",
          rotate: initialAngleX,
        });
        Draggable.create(".videorotary__rotary", {
          type: "rotation",
          minRotation: 0,
          inertia: true,
          trigger: ".videorotary__trigger",
          onDrag: function () {
            updateAngleX(calculateAngle(this.rotation));
          },
          onDragEnd: function () {
            updateFinalX(calculateAngle(this.rotation));
          },
        });
      }
    }, videorotary);
    return () => ctx.revert();
    // eslint-disable-next-line
  }, [videorotary]);

  return (
    <div className="videoRotary__content">
      {coordinates && coordinates.north && (
        <span className="north | uppercase">{coordinates.north}</span>
      )}
      {coordinates && coordinates.east && (
        <span className="east | uppercase">{coordinates.east}</span>
      )}
      {coordinates && coordinates.south && (
        <span className="south | uppercase">{coordinates.south}</span>
      )}
      {coordinates && coordinates.west && (
        <span className="west | uppercase">{coordinates.west}</span>
      )}
      <svg
        ref={videorotary}
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 267.6 267.6"
      >
        <circle className="fill-light-primary" cx="133.8" cy="133.8" r="124" />
        <circle
          className="stroke-dashed stroke-primary"
          cx="133.8"
          cy="133.8"
          r="75.6"
        />
        <g className="videorotary__rotary">
          <circle className="transparent" cx="133.8" cy="133.8" r="124" />
          <path
            className="videorotary__trigger fill-primary"
            d="M180.9 19.1c-14.5-6-30.4-9.3-47.1-9.3-16.7 0-32.6 3.3-47.1 9.3l47.1 114.1 47.1-114.1z"
          />
        </g>
        {coordinates && (
          <path
            className="stroke-primary"
            d="M19.6 133.8H0M267.6 133.8H248M133.8 19.6V0M133.8 267.6V248"
          />
        )}
      </svg>
    </div>
  );
};

export default VideoRotary2D;
