/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect } from "react";
import { login } from "../../store/auth/auth.actions";
import { decrypt } from "../../utils/encrypt.utils";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import { getContentReducer } from "../../store/content/content.selector";

import Loader from "../../components/Loader/Loader.component";
import Message from "../../components/Message/Message.component";

import "./SignInQRCode.styles.scss";

const SignInQRCode = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { userToken, loading, error } = useSelector(getAuthReducer);
  const {
    texts: { qrcodeError },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(
      login({
        email: decrypt(searchParams.get("u"), process.env.REACT_APP_CRYPT),
        password: decrypt(searchParams.get("p"), process.env.REACT_APP_CRYPT),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  /* gestion des redirections */
  if (userToken) return <Navigate to="/" replace />;

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="min-h-screen flex flex-wrap content-between">
      <div className="w-full flex justify-center items-center">
        <div className="max-w-md w-10/12">
          {error && (
            <Message
              type="error"
              text={qrcodeError}
              side="center"
              main="main"
            />
          )}
          {loading && (
            <div className="relative h-56">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInQRCode;
