import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  getCurrentProject,
  getProjectsReducer,
} from "../store/projects/projects.selector";
import {
  setLots,
  setProgrammeDatas,
} from "../store/programme/programme.actions";
import {
  emptyLots,
  emptyProgramme,
} from "../store/programme/programme.reducer";
import { getScale } from "../utils/helpers.utils";
import { getProgrammeReducer } from "../store/programme/programme.selector";
import {
  setSocketsSearchBackground,
  setSocketsSearchImages,
} from "../utils/websockets.utils";
import { getAuthReducer } from "../store/auth/auth.selector";

/* Routes de recherche */
export const SearchRoute = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { projectID, scaleID } = useParams();
  const { lots, programmeDatas } = useSelector(getProgrammeReducer);
  const { socketID } = useSelector(getAuthReducer);
  const currentProject = useSelector(getCurrentProject(projectID));
  const projectsReducer = useSelector(getProjectsReducer);

  const {
    searchBackgroundImage,
    searchBatiment,
    searchDetails,
    searchPrintOnly,
    lots: { data: printLots },
  } = useMemo(
    () => getScale(projectsReducer, projectID, scaleID),
    [projectID, scaleID, projectsReducer]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    const triggerDispatchs = async () => {
      if (currentProject && currentProject?.programme && searchBatiment) {
        const programmeName = currentProject.programme;
        dispatch(setProgrammeDatas({ programmeName }));

        dispatch(
          setLots({
            programmeName,
            searchBatiment,
            searchDetails,
            searchPrintOnly,
            printLots: printLots.map((printLot) =>
              printLot && printLot?.attributes?.slug
                ? printLot.attributes.slug
                : ""
            ),
          })
        );
      }
    };
    triggerDispatchs();

    return () => {
      dispatch(emptyProgramme());
      dispatch(emptyLots());
    };
    // eslint-disable-next-line
  }, [
    currentProject,
    searchBatiment,
    searchDetails,
    searchPrintOnly,
    printLots,
  ]);

  /* envoi images à charger */
  useEffect(() => {
    if (lots && programmeDatas && programmeDatas?.name && socketID) {
      setSocketsSearchImages(
        lots.map((lot) => lot.name),
        programmeDatas.name,
        socketID
      );
    }
  }, [lots, programmeDatas, socketID]);

  /* envoi images de fond */
  useEffect(() => {
    if (searchBackgroundImage && socketID) {
      setSocketsSearchBackground(searchBackgroundImage, socketID);
    }

    return () => {
      setSocketsSearchBackground(null, socketID);
    };
  }, [searchBackgroundImage, socketID]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return <Outlet />;
};
